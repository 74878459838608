import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, withRouter, useRouteMatch, useHistory } from "react-router-dom";
import store from "../../redux/store";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import { MdSearch } from "react-icons/md";
import { BiBookAdd } from "react-icons/bi";

interface ManualBookingState {
  // keys_out?: number;
  // fobs_out?: number;
  // park_out?: number;
  // mailkey_out?: number;
  // allTenants: Tenant[];
  // currentTenant: Tenant;
  // new_tenant: boolean;
  // outstanding_invoice?: number;
  // corporate: boolean;
  // bottomBar_Toggle?: boolean;
  // monthly_rate?: number;
  // admin_contact: string;
  // admin_contact_email: string;
  // admin_contact_phone: number;
  // finance_contact: string;
  // finance_contact_email: string;
  // finance_contact_phone: number;
  // cleaning_contact: string;
  // cleaning_contact_email: string;
  // cleaning_contact_phone: number;
  // corporate_id: string;
  // corporate_deduction: number;
  // deduction_percent: bool;
  // tenant_id: number;
  tenant_first_name: string;
  tenant_last_name: string;
  tenant_email: string;
  tenant_phone: string;
  search: string;
  newTenant: boolean;
  tenantList: Tenant[];
  showButtons: boolean;
  // corp_list: string[];
}

type Tenant = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  id: number;
};

type ManualBookingProps = {
  ErrorNote: any;
  history: any;
};

class ManualBooking extends Component<ManualBookingProps, ManualBookingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      // currentTenant: {
      //   email: "",
      //   first_name: "",
      //   last_name: "",
      //   phone_number: "",
      //   id: -1,
      // },
      // keys_out: 0,
      // fobs_out: 0,
      // park_out: 0,
      // mailkey_out: 0,
      // allTenants: [],
      // new_tenant: false,
      // outstanding_invoice: 0,
      // corporate: false,
      // bottomBar_Toggle: true,
      // monthly_rate: 0,
      // corporate_id: "",
      // corporate_deduction: 0,
      // deduction_percent: true,
      // admin_contact: "",
      // admin_contact_email: "",
      // admin_contact_phone: 0,
      // finance_contact: "",
      // finance_contact_email: "",
      // finance_contact_phone: 0,
      // cleaning_contact: "",
      // cleaning_contact_email: "",
      // cleaning_contact_phone: 0,
      // tenant_id: -1,
      tenant_first_name: "",
      tenant_last_name: "",
      tenant_email: "",
      tenant_phone: "",
      search: "",
      tenantList: [],
      newTenant: false,
      showButtons: false
      // corp_list: [],
    };
  }

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/26635
  // handleCorporateToggle = () => {
  //   this.setState({ corporate: !this.state.corporate });
  // };

  // handlePercentToggle = () => {
  //   this.setState({ deduction_percent: !this.state.deduction_percent });
  // };

  // Look up email for exising tenant
  // searchEmail = async (event: any) => {
  //   console.log("Searching Email");
  //   event.preventDefault();
  //   let NotificationHandler = this.props.ErrorNote;
  //   let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
  //   let tenantData = allTenants.data.find(
  //     (tenantItem: tenantType) => tenantItem.email === this.state.tenant_email
  //   );
  //   if (tenantData) {
  //     this.setState({
  //       tenant_first_name: tenantData.first_name,
  //       tenant_last_name: tenantData.last_name,
  //       tenant_phone: tenantData.phone_number,
  //     });
  //     NotificationHandler("Success", "Tenant Found");
  //   } else {
  //     // alert("No tenant is associated with that email");
  //     NotificationHandler(
  //       "Warning",
  //       "Tenant Not Found",
  //       "No existing tenant is associated with that email. If you are creating a new tenant, do not search but continue to manually fill their name and phone number."
  //     );
  //   }
  // };


//   updateSearch = async (event: any) => {
//     event.preventDefault();
//     let NotificationHandler = this.props.ErrorNote;
//     let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);

//     console.log(allTenants)
//     console.log(this.state.allTenants.length)
//     console.log(this.state.allTenants[0].email)

//     if (allTenants.status == "Success"){
//       let email = event.target.value;
//       for (let i = 0; i < this.state.allTenants.length; i++) {
//         console.log("inside for loop")
//         console.log(email)
//         console.log(this.state.allTenants[i].email)
//         if (this.state.allTenants[i].email == email){
//           console.log("Match Found")
//           this.setState({
//             currentTenant: this.state.allTenants[i],
//           });
//         }
//     }
//   }else{
//     NotificationHandler("No tenant with this email exists")
//   }
// }

  // updateSearch = async (event: any) => {
  //   let NotificationHandler = this.props.ErrorNote;
  //   let allTenantEmails = await fetchRequestJSON(
  //     "POST",
  //     "/tenant/search-email",
  //     {
  //       email: this.state.tenant_email.trim()
  //     }
  //     );
  //     if(!this.state.tenant_email.includes("@"))
  //     {
  //       NotificationHandler('Error', 'Please Enter a valid tenant email', `${this.state.tenant_email.trim()}`);
  //       return;
  //     }
  //     console.log(allTenantEmails.status)
  //     if (allTenantEmails.status == "Success"){
  //       await this.setState({
  //         currentTenant: allTenantEmails.data
  //       });
  //       NotificationHandler('Success', 'Success: Tenant Found', `${this.state.tenant_email.trim()}`);
  //     }else if(allTenantEmails.status != "Success"){
  //       NotificationHandler('Error', 'No Tenant with this email exist:', `${this.state.tenant_email.trim()}`);
  //     }
  // };

  displayButtons = () => {
    this.setState({ showButtons: true });
  }

  handleNewTenant = () => {
    this.setState({ newTenant: !this.state.newTenant });
  };

  searchTenant = async () => {
    // if someone clicks new tenant but chooses to search a tenant after
    if (this.state.newTenant) {
      this.handleNewTenant();
    }
    this.displayButtons();

    let NotificationHandler = this.props.ErrorNote;
    let tenants = await fetchGetJSON(`${Constants.API_PATH_TENANT_SEARCH}?q=${this.state.search.trim()}`,);
    console.log(tenants);

    if (tenants.status === "Success"){
      NotificationHandler('Success', 'Success: Tenant Found', `${this.state.search.trim()}`);

      let tenantsFound = [];
      for (let i = 0; i < tenants.data.length; i++) {
        const tenant :Tenant = {
          id: tenants.data[i].id,
          email: tenants.data[i].email ? tenants.data[i].email : "N/A",
          first_name: tenants.data[i].first_name ? tenants.data[i].first_name : "N/A",
          last_name: tenants.data[i].last_name ? tenants.data[i].last_name : "N/A",
          phone_number: tenants.data[i].phone_number ? tenants.data[i].phone_number : "N/A"
        };
        tenantsFound.push(tenant);
      }

      this.setState({tenantList: tenantsFound});
      console.log(tenantsFound);
    } 
    else {
      NotificationHandler(tenants.status, `${tenants.status == "Error Unauthorized" ? 'Unauthorized':tenants.data}`, `${tenants.status == "Error Unauthorized" ? 'Unauthorized access. Please Contact Dev Team': 'Please search a valid tenant or create a new one'}`);
      this.setState({tenantList: []});
    }
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // handleTenantChange = async (event: any) => {
  //   event.preventDefault();
  //   let email = event.target.value;
  //   let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
  //   if (allTenants.status == "Success"){
  //     for (let i = 0; i < this.state.allTenants.length; i++) {
  //       if (this.state.allTenants[i].email == email){
  //         this.setState({
  //           currentTenant: this.state.allTenants[i],
  //         });
  //       }
  //     }
  //   };
  //   await this.setState({
  //     tenant_email: email,
  //   })
  // }

  // newTenantToggle = () => {
  //   if (this.state.new_tenant) {
  //     let currTenant = {
  //       email: "",
  //       phone_number: "",
  //       first_name: "",
  //       last_name: "",
  //       id: -1,
  //     };
  //     if (this.state.allTenants.length > 0) {
  //       currTenant = this.state.allTenants[0];
  //     }
  //     console.log(currTenant)
  //     this.setState({
  //       new_tenant: false,
  //       currentTenant: currTenant,
  //     });
  //   } else {
  //     this.setState({
  //       new_tenant: true,
  //       currentTenant: {
  //         email: "",
  //         phone_number: "",
  //         first_name: "",
  //         last_name: "",
  //         id: -1,
  //       },
  //     });
  //   }
  // };

  // componentDidMount = async () => {
  //   let allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
  //   let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
  //   let allCorpContacts = await fetchGetJSON(Constants.API_PATH_CORP_CONTACT_GET_ALL);
    // let allTenantEmails = await fetchGetJSON(Constants....);
  //   let corpList = ["None"];
  //   for (let i = 0; i < allCorps.data.length; i++) {
  //     corpList.push(allCorps.data[i].corporate_name);
  //   }
  //   let tenantData: Tenant[] = [];
  //   let curTenant: Tenant = this.state.currentTenant;
  //   if (allTenants.data.length > 0) {
  //     tenantData = allTenants.data;
  //     curTenant = tenantData[0];
  //   }

  //   this.setState({
  //     corp_list: corpList,
  //     allTenants: tenantData,
  //     currentTenant: curTenant,
  //   });
  //   let tenantId = store.getState().tenantReducer.curId;
  //   if (tenantId > 0) {
  //     let selected = allTenants.data.find((tenant: tenantType) => tenant.id == tenantId)
  //     if (!selected) {
  //       return;
  //     }

  //     this.setState({
  //       tenant_id: selected.id,
  //       tenant_email: selected.email,
  //       tenant_first_name: selected.first_name,
  //       tenant_last_name: selected.last_name,
  //       tenant_phone: selected.phone_number,
  //       currentTenant: selected
  //     });

  //   }
  // };

  // createSelectItems() {
  //   let items = [];
  //   for (let i = 0; i < this.state.corp_list.length; i++) {
  //     items.push(
  //       <option key={i} value={this.state.corp_list[i]}>
  //         {this.state.corp_list[i]}
  //       </option>
  //     );
  //   }
  //   return items;
  // }

  addTenant = (event: any, tenantIndex: number) => {
    event.preventDefault();
    store.dispatch({ type: "setBookingTenant", tenant: this.state.tenantList[tenantIndex]});
    store.dispatch({ type: "setBooking", booking: undefined });
    const history = this.props.history;
    const path = history.location.pathname;
    history.push(`${path}/info`);
  }

  submitNewTenant = (event: any) => {
    event.preventDefault();

    if (!(this.state.tenant_email && this.state.tenant_first_name && this.state.tenant_last_name)) {
      let NotificationHandler = this.props.ErrorNote;
      NotificationHandler('Error', 'No contact information', 'Please ensure that email, first name, and last name fields are all filled in');
      return;
    }

    const newTenant = {
      email: this.state.tenant_email ? this.state.tenant_email : null,
      first_name: this.state.tenant_first_name ? this.state.tenant_first_name : null,
      last_name: this.state.tenant_last_name ? this.state.tenant_last_name : null,
      phone_number: this.state.tenant_phone ? this.state.tenant_phone : null
    };
    store.dispatch({ type: "setBookingTenant", tenant: newTenant});
    store.dispatch({ type: "setBooking", booking: undefined });
    const history = this.props.history;
    const path = history.location.pathname;
    history.push(`${path}/info`);
  }

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.searchTenant()
    }
  }

  render() {
    const rows = [];
    let tenantTable = <></>;
    let buttons = <></>;
    let newTenantInfo = <></>;
    
    if (this.state.newTenant) {
      newTenantInfo = (
        <div className="flex flex-col mt-8 ml-7">
          <div className="flex flex-row">
            <label className="block w-1/3">
              <input
                id="tenant_email"
                onChange={this.handleInputChange}
                type="text"
                className="text-sm transition-all duration-400 text-gray-600 py-1
                  w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-400
                  focus:ring-0 focus:border-gray-500"
                placeholder="Enter Tenant Email"
                value={this.state.tenant_email}
              />
            </label>
          </div>

          <div className="flex flex-row mt-10 ">
            <label className="block w-1/4">
              <input
                id="tenant_first_name"
                onChange={this.handleInputChange}
                value={this.state.tenant_first_name}
                type="text"
                className="text-sm transition-all duration-400 text-gray-600 py-1
                  w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-400
                  focus:ring-0 focus:border-gray-500"
                placeholder="Enter First Name"
              />
            </label>

            <label className="block ml-10 w-1/4">
              <input
                id="tenant_last_name"
                onChange={this.handleInputChange}
                value={this.state.tenant_last_name}
                type="text"
                className="text-sm transition-all duration-400 text-gray-600 py-1
                  w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-400
                  focus:ring-0 focus:border-gray-500"
                placeholder="Enter Last Name"
              />
            </label>
          </div>
          
          <div className="flex flex-row mt-10">
            <label className="block w-1/4">
              <i className="text-gray-400 absolute mt-2 text-sm">
                <FaPhoneAlt />
              </i>
              <input
                id="tenant_phone"
                onChange={this.handleInputChange}
                value={this.state.tenant_phone}
                type="text"
                className="text-sm transition-all duration-400 text-gray-600
                  py-1 pl-5 w-full border-0 border-b-2 border-gray-300 placeholder-gray-400
                  focus:ring-0 focus:border-gray-500"
                placeholder="Enter Phone Number"
              />
            </label>
          </div>
        </div>
      );

      buttons = (
        <div className="flex flex-row justify-start mt-10">
          <Link to="/user/bookings">
            <button className=" font-SF-Pro transition text-md duration-100 w-28
              hover:bg-green-100 text-green-500 font-bold py-2 rounded pr-1 flex
              flex-row justify-center align-center mr-3">
              Back
            </button>
          </Link>

          <button
            onClick={(event) => {this.submitNewTenant(event)}}
            className=" font-SF-Pro shadow-sm transition text-md duration-100 w-28 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded pr-1 flex flex-row justify-center align-center mr-3"
          >
            Next
          </button>
        </div>
      );
    } else {
      if (this.state.tenantList.length > 0) {
        for (let i = 0; i < this.state.tenantList.length; i++) {
          let tableRows = (
            <tr className=" cursor-pointer border-t border-gray-400 w-full" key={this.state.tenantList[i].id}>
              {/* ID */}
              <td
                className=" w-16 font-medium text-sm text-gray-500 px-1  py-4"
              >
                {this.state.tenantList[i].id}
              </td>
  
              {/* First Name */}
              <td
                className=" w-16 font-light text-sm text-gray-500 px-1 py-4"
              >
                <div className=" flex flex-col ">
                  <span className="text-base text-gray-600 font-semibold truncate w-32">{this.state.tenantList[i].first_name}</span>
                </div>
              </td>
  
              {/* Last Name */}
              <td
                className=" w-16 font-light text-sm text-gray-500 px-1 py-4"
              >
                <div className=" flex flex-col ">
                  <span className="text-base text-gray-600 font-semibold truncate w-32">{this.state.tenantList[i].last_name}</span>
                </div>
              </td>
  
              {/* Email */}
              <td
                className=" hidden lg:table-cell w-24 px-1 py-4 text-sm"
              >
                {this.state.tenantList[i].email}
              </td>
  
              {/* Phone Number */}
              <td
                className=" hidden lg:table-cell w-16 font-thin text-gray-500 text-sm px-1 py-4"
              >
                {this.state.tenantList[i].phone_number}{" "}
              </td>
  
              <td
                className=" w-16 px-1 py-4 "
              >
                <div className="flex flex-row justify-end">
                  <BiBookAdd
                    onClick={(e) => {this.addTenant(e, i)}}
                    className={"text-green-500 text-2xl mr-2 hover:text-green-400"}
                  />
                </div>
              </td>
            </tr>
          );
          rows.push(tableRows);
        }
  
        tenantTable = (
          <div className="ml-7">
            <div className="text-green-500 font-bold text-lg underline">Tenant Search Results</div>
            <div className="flex flex-row mt-3">
              <table className="table-auto w-full mb-8">
                <thead>
                  <tr className="text-left font-normal text-gray-700 " key={0}>
                    <th className="py-2 ">ID</th>
                    <th className="py-2  ">First Name</th>
                    <th className="py-2  ">Last Name</th>
                    <th className="py-2 hidden lg:table-cell   ">Email</th>
                    <th className="py-2 hidden lg:table-cell  ">Phone</th>
                    {/* Options */}
                    <th className="py-2  "></th>
                  </tr>
                </thead>
  
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        );
      }

      if (this.state.showButtons) {
        buttons = (
          <div className="flex flex-row justify-start mt-3">
            <Link to="/user/bookings">
              <button className=" font-SF-Pro transition text-md duration-100 w-28
                hover:bg-green-100 text-green-500 font-bold py-2 rounded pr-1 flex
                flex-row justify-center align-center mr-3">
                Back
              </button>
            </Link>
  
            <button
              onClick={() => {this.handleNewTenant()}}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-48 bg-green-500 hover:bg-green-400 
              text-white font-bold py-2 rounded pr-1 flex flex-row justify-center align-center mr-3"
            >
              Create a New Tenant
            </button>
          </div>
        );
      }
    }
    // let corporateSection;
    // if (this.state.corporate) {
    //   corporateSection = "opacity-100 translate-y-0";
    // } else {
    //   corporateSection = "opacity-0 transform  -translate-y-10 ";
    // }

    // let content = <> </>;

    // let newTenantStyling;
    // if (this.state.new_tenant) {
    //   newTenantStyling = "font-bold text-gray-600";
    // } else {
    //   newTenantStyling = "text-gray-300";
    // }

    // let notNewTenantStyling;
    // if (!this.state.new_tenant) {
    //   notNewTenantStyling = "font-bold text-gray-600";
    //   content = (
    //     <div>
    //       <label className="block pl-3 mb-5">
    //         <span className="text-gray-400 text-xs">Tenant</span>
    //         {/* <select
    //           id="tenant"
    //           onChange={this.handleTenantChange}
    //           placeholder={`${this.state.currentTenant.first_name} ${this.state.currentTenant.last_name}`}
    //           className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
    //         > */}
            
    //         {/* Changed */}
    //         <section className="flex flex-row">
    //           <span className="flex flex-row border rounded-sm w-full lg:w-1/4  py-1">
    //             <MdSearch className="text-xl text-gray-400 mx-1" />
    //             <input
    //               className="text-sm focus:border-0 focus:outline-none "
    //               onChange={event => this.handleTenantChange(event)}
    //               placeholder= "Search Tenant"
    //               id="tenant_email"
    //               // value={this.state.currentTenant.email}
    //               defaultValue = {this.state.currentTenant.email}
    //             />
    //           </span>
    //           <button className="ml-2 bg-green-500 hover:bg-green-400 rounded-sm text-sm py-1 px-2 text-white" onClick={this.updateSearch}>Search</button>
             
    //         </section>
    //           {/* {this.state.allTenants.map((tenant) => (
    //             <option value={tenant.id} selected={this.state.tenant_id == tenant.id}>
    //               {`${tenant.first_name} ${tenant.email}`}
    //             </option>
    //           ))} */}
    //         {/* </select>  */}
    //       </label>{" "}
    //     </div>
    //   );
    // } else {
    //   notNewTenantStyling = "text-gray-300";
    // }

    return (
      <div className="  font-display bg-white lg:rounded-tl-lg ">
        <BreadCrumb
          rootPage="Bookings"
          subPage="New > Manual Booking"
          sideButton=""
          buttonAction=""
        />
        <div className=" fade-in px-10 mt-16 lg:mt-0 max-w-5xl pb-24">
          {/* Heading Info Area  */}
            <div className="flex flex-row mb-6">
              <div className="flex flex-col w-full">
                <h1 className="text-2xl px-2 pt-3 text-gray-700 font-black font-display ">
                  Create a Manual Booking
                </h1>
                <h2 className="px-2 font-normal font-display text-med text-gray-600">
                  Steps:
                </h2>
                <h2 className="px-2 font-normal font-display text-sm text-gray-500 ml-2">
                  <ol className="list-decimal ml-8">
                    <li>Search for a tenant</li>
                    <li>If you find the tenant you want to create a booking for, click the Booking icon to continue</li>
                    <li>If not, click "Create a New Tenant" at the bottom of the list of tenants</li>
                    <li>Enter new tenant info and click "Next" to proceed with the booking creation</li>
                  </ol>
                  <p>If at any point you made a mistake, you can click "Back" to go back to the Bookings page.</p>
                </h2>
              </div>
            </div>

            {/* <div className=" flex flex-row pl-3 my-5 ">
              <span className={`${notNewTenantStyling} transition-all duration-300 text-lg pr-3`}>
                Existing Tenant
              </span>

              <ToggleSwitch
                id="corporate"
                handleStateToggle={this.newTenantToggle}
                size="lg"
                onoff={this.state.new_tenant}
              />

              <span className={`${newTenantStyling} transition-all duration-300 text-lg pr-3 mx-5`}>
                New Tenant
              </span>
            </div> */}

          <div>
            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Tenant</span>
              
              {/* Changed */}
              <section className="flex flex-row">
                <span className="flex flex-row border rounded-sm w-full lg:w-1/3 h-8 py-1">
                  <MdSearch className="text-xl text-gray-400 mx-1" />
                  <input
                    className="text-sm searchBox w-full"
                    onChange={this.handleInputChange}
                    placeholder= "Search Tenant by Email or Name"
                    id="search"
                    value={this.state.search}
                    onKeyDown={this.handleKeyDown}
                  />
                </span>
                <button className="ml-2 bg-green-500 hover:bg-green-400 rounded-sm text-sm py-1 px-2 text-white" onClick={() => {this.searchTenant()}}>Search</button>
              </section>
            </label>{" "}
          </div>

          {/* Tenants Table */}
          {tenantTable}

          {/* New Tenant */}
          {newTenantInfo}

          {/* Buttons */}
          {buttons}

          {/* <TabList warning={false}>
            <TabLabel label="Tenants">
              <TenantsList handleQuickView={this.handleQuickView} />
            </TabLabel>
            <TabLabel label=""/>
          </TabList> */}
        </div>
      </div>
    );
  }
}

export default withRouter(withHooksHOC(ManualBooking));

// function ManualBookingLink(props: any) {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.

//   let { path, url } = useRouteMatch();
//   let history = useHistory();

//   const handleSubmit = (event: any) => {
//     // Make a tenant for the booking and pass its properties to reducer

//     const tenant = {
//       last_name: props.data.tenant_last_name,
//       first_name: props.data.tenant_first_name,
//       phone_number: props.data.tenant_phone_number,
//       email: props.data.tenant_email
//       // id: props.data.new_tenant ? -1 : props.data.currentTenant.id
//     };
//     console.log(tenant);
//     store.dispatch({ type: "setBookingTenant", tenant: tenant });
//     // store.dispatch({
//     //   type: "setBookingCorpDeduction",
//     //   deduction: props.data.corporate_deduction,
//     // });
//     // store.dispatch({
//     //   type: "setBookingCorpDeductionType",
//     //   deduction_type: props.data.deduction_percent,
//     // });

//     event.preventDefault();
//     history.push(`${path}/info`);
//   };

//   return (
//     <button
//       onClick={handleSubmit}
//       className=" font-SF-Pro shadow-sm transition text-md duration-100 w-28 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded pr-1 flex flex-row justify-center align-center mr-3"
//     >
//       Next
//     </button>
//   );
// }
