import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { FiSettings, FiPauseCircle, FiPlus, FiCheck } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { unitType, listview } from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";
import { withHooksHOC } from "../Notifications/NotificationProvider";


// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  handleState: any;
  handleStateActive:any;
  active: boolean;
  ErrorNote: any;
};

type State = {
  blackout: boolean;
  units: Unit[];
  blackouts: Blackout[];
  search: string;
  // units: [{
  //     suite_name: string,
  //     suite_number: number,
  //     min_stay: number,
  //     bedrooms: number,
  //     type: string,
  //     exists: boolean
  // }]
  filter_blackout: boolean;
  filter_vacant: number;
  filter_issues: boolean;

  vacants: any[];
  loaded: boolean;

  settingId: number;

  current_page_number: number;
  rows_per_page: number;
  total_pages: number;
  authorization: boolean;
};

type Unit = {
  suite_name: string;
  suite_number: string;
  min_stay: number;
  type: string;
  bedrooms: number;
  id: number;
  active: boolean;
  bed_configuration: string;
  sofa_bed: boolean;
  isQuadable: boolean;
  quad_status: boolean;
};

type Blackout = {
  id: number;
  unit_id: number;
  start_date: string;
  end_date: string;
};

class UnitList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      // units: [{
      //     suite_name: "",
      //     suite_number: -1,
      //     min_stay: -1,
      //     bedrooms: -1,
      //     type: "",
      //     exists: false,
      //     id: -1
      // }]
      units: [],
      blackouts: [],
      search: "",
      filter_issues: false,
      filter_blackout: false,
      filter_vacant: 0,

      vacants: [],

      loaded: false,
      settingId: -1,

      current_page_number: 1,
      rows_per_page: 10,
      total_pages: 0,
      authorization: true,
    };
  }

  cancelBlackout = async (unit: number) => {
    let Blackout = this.state.blackouts.filter(bo => bo.unit_id == unit);
    let target;
    if (Blackout.length != 0) {
      target = Blackout.find((bo) => {
        let today = new Date();
        return new Date(bo.start_date) <= today && today <= new Date(bo.end_date);
      })
    }
    //console.log(target);

    let del = await fetchRequestJSON(
      'PUT',
      Constants.API_PATH_BLACKOUT_SHORTEN,
      {id: target.id}
    );

    if(del.status !== "Success") {
        alert("There was a problem deleting this blackout.")
    } else {
        window.location.reload();
    }
  };

  handleModal = (id: string, unitId: number) => {
    store.dispatch({ type: "unitIdTransfer", id: unitId });
    this.props.handleState(id, unitId);
  };

  handleActiveModal = (id: string, unitId: number) => {
    store.dispatch({ type: "unitIdTransfer", id: unitId });
    this.props.handleStateActive(id, unitId);
  };

  HandleBlackOut = () => {
    if (this.state.blackout === false) {
      this.setState({ blackout: true });
    } else {
      this.setState({ blackout: false });
    }
  };

  HandleRowClick = (id: number) => {

    store.dispatch({ type: "unitIdTransfer", id: id });
    // console.log(id)
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: `${location.pathname}/unitprofile`,
        search: `id=${id}`,
      });
  };

  // Not used
  // openSettings = () => {
  //   alert("Settings");
  // };

  pageUp = async () => {
    let page = this.state.current_page_number + 1;
    if (page > this.state.total_pages) {
      page -= 1;
    }

    this.setState({
      current_page_number: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.current_page_number - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      current_page_number: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({rows_per_page: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.current_page_number > this.state.total_pages) {
        this.setState({
          current_page_number: this.state.total_pages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  // Gets the entire list of the existing units and populates this.state.units so they can be rendered
  componentDidMount = async () => {
    // console.log(process.env.REACT_APP_AWS_API_GATEWAY_LINK)
    // Might want to put this into updateList incase you update the list of blackouts?
    let all_blackouts = await fetchGetJSON(
      Constants.API_PATH_BLACKOUT_GET_ALL
    );
    this.setState({ blackouts: all_blackouts.data });
    this.updateList();
    store.dispatch({ type: "unitListUpdate", listUpdate: this.updateList });

    //commented out due to loading issues with large data sets
    // let vacants = await fetchGetJSON(Constants.API_PATH_TODAY_GET_VACANT);
    // if (vacants.status === "Success") {
    //   this.setState({ vacants: vacants.data.vacants });
    // }
    
  };

  updateList = async (unitName?: string) => {
    let filters = {
      search: "",
      active: this.props.active
    }
    filters.search = this.state.search;
    console.log({filters: filters, page: this.state.current_page_number, perpage: this.state.rows_per_page});
    const allUnits: listview<Unit[]> = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_UNITS}?page=${this.state.current_page_number}&perpage=${this.state.rows_per_page}&search${filters.search.length ? `=${filters.search}`: ''}&active=${filters.active}`, 
      // {filters: filters, page: this.state.current_page_number, perpage: this.state.rows_per_page}
    )

    let unitList = [];
    if (allUnits.status !== "Success") {
      this.setState({
        loaded: true,
        authorization: allUnits.status == "Error Unauthorized" ? false : true
      })

      return;
    }

    this.setState({total_pages: allUnits.data.pages})

    // Loops over all the units and set up unit objects to be pushed into unitList
    for (let unit of allUnits.data.rows) {

      if(unit.active == this.props.active)
      {
        unitList.push(unit);
      }
      // unitObj.exists = true
      // console.log(this.state.units)
    }
    this.setState({ units: unitList, loaded: true });
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);

    // this.updateList(this.state.search);
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    // const { history } = this.props;
    // TODO: Get a list of all active, but vacant units.

    let rows = [];
    for (let i = 0; i < this.state.units.length; i++) {
      // TODO: The code below contains a TypeError: this.state.vacants is not a function.
      if (this.state.filter_vacant) {
        if (this.state.filter_vacant == 1) {
          if (!this.state.vacants.find((unit) => { return (unit.id == this.state.units[i].id)})) {
            continue;
          }
        } else {
          if (this.state.vacants.find((unit) => { return (unit.id == this.state.units[i].id)})) {
            continue;
          }
        }
      }

      let unitId = this.state.units[i].id;
      let active = this.state.units[i].active;

      // if (!(this.state.search == "" ||
      //   this.state.units[i].suite_name.toLowerCase().includes(this.state.search.toLowerCase()))) {
      //   continue;
      // }

      let Blackout = this.state.blackouts.filter(bo => bo.unit_id == this.state.units[i].id);
      let target = null;
      if (Blackout.length != 0) {
        target = Blackout.find((bo) => {
          let today = new Date();
          return new Date(bo.start_date) <= today && today <= new Date(bo.end_date);
        })
      }

      if (this.state.filter_blackout && !target) {
        continue;
      }

      let blackoutStyles = "transition-all duration-200";
      let Warnings = "";
      let blackoutToggle = (
        <button
          onClick={() => {
            this.handleModal("newBlackout", unitId);
          }}
        >
          <FiPlus className={"text-3xl text-gray-700 hover:text-green-500"} />
        </button>
      );

      if (target) {
        blackoutStyles = "transition-all duration-200 opacity-10 ";
        Warnings = "Do Not Book";
        blackoutToggle = (
          <button
            onClick={() => {
              this.cancelBlackout(unitId);
            }}
          >
            <FaPowerOff
              className={"text-2xl text-green-500 hover:text-green-300"}
            />
          </button>
        );
      }

      let TableRows = (
        <tr className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full">
          <td
            className={
              blackoutStyles +
              " flex flex-row justify-center lg:table-cell  w-auto lg:w-16 px-1 py-4"
            }
          >
            <h3 className="text-indigo-500"> {unitId}</h3>
          </td>

          {/* Suite Name */}

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles +
              " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
            }
          >
            {/* <Tooltip content={<UnitBubble />} direction="" delay=""> */}
            {/* <div
              id="bookingConfirmed"
              className="  cursor-pointer  h-8 lg:h-6 lg:mr-2 bg-gray-700 text-white font-medium text-lg lg:text-2xs rounded-full py-1 w-auto  flex items-center  text-center px-5"
            >
              {" "} */}
            <h3>{this.state.units[i].suite_name}</h3>
            {/* </div> */}
            {/* </Tooltip> */}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles +
              " w-36 font-light text-sm text-gray-500 px-10 py-4"
            }
          >
            <h3>{this.state.units[i].suite_number}</h3>
          </td>

          {/* Min. Stay */}

          {/* <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-32 font-medium  text-gray-700 px-1  py-4"
            }
          >
            {this.state.units[i].min_stay}
          </td> */}

          {/* Bedrooms */}

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-32 font-medium  text-gray-700 px-1  py-4"
            }
          >
            {this.state.units[i].bedrooms} Bed
          </td>

          {/* Type */}

          {/* <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-28 font-normal  text-gray-400 px-0.5  py-4"
            }
          >
            {this.state.units[i].type}
          </td> */}

          {/* Bed Config */}

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-28 font-normal text-gray-400 px-1  py-4"
            }
          >
            {this.state.units[i].bed_configuration}
          </td>

          {/* Sofa Bed */}

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-28 font-normal  text-gray-400 px-1  py-4"
            }
          >
            {`${this.state.units[i].sofa_bed}`}
          </td>

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-28 font-normal  text-gray-400 px-1  py-4"
            }
          >
            {this.state.units[i].isQuadable ? <FiCheck className="text-green-400 text-2xl"></FiCheck> : <MdCancel className="text-red-400 text-2xl"></MdCancel>}
          </td>

          <td
            onClick={() => this.HandleRowClick(unitId)}
            className={
              blackoutStyles + " w-28 font-normal  text-gray-400 px-1  py-4"
            }
          >
            {this.state.units[i].quad_status ? <FiCheck className="text-green-400 text-2xl"></FiCheck> : <MdCancel className="text-red-400 text-2xl"></MdCancel>}
          </td>

          {/* Booking Warnings */}
          {
            this.props.active && 
            <td
            onClick={() => this.HandleRowClick(unitId)}
            className=" w-28 text-red-400 px-1 py-4 text-sm font-semibold"
            >
              {Warnings}
            </td>
          }
 

          {/* Booking Warnings */}
          {
            active &&           
            <td className=" hidden  lg:table-cell lg:w-32 px-1 py-4  ">
              <div className="flex flex-row justify-center">
                <button
                  onClick={() => {
                    this.handleActiveModal("activeState", unitId);
                  }}
                >
                  <FiPauseCircle className={"text-4xl text-gray-700 hover:text-red-500"} />
                </button>
              </div>
            </td>
          }

          {
            this.props.active && 
              <td className=" hidden  lg:table-cell lg:w-32 px-1 py-4  ">
              <div className="flex flex-row justify-center">
                {
                  (this.state.settingId == unitId) && <button
                    className="w-32 rounded absolute ml-auto right-0 -mt-10 py-2 text-center shadow-xl bg-gray-100"
                    onClick={async () => {
                      let NotificationHandler = this.props.ErrorNote;
                      let res = await fetchRequestJSON("PUT", Constants.API_PATH_UNIT_TOGGLE_ACTIVE, {
                        id: unitId
                      })
                      console.log("Trying to activate/deactivate unit: ");
                      console.log(res);
                      if (res.status == "Success") {
                        console.log("Succesful change to activate/deactive")
                        this.updateList();
                      } else {
                        NotificationHandler("Error", "Error", res.data)
                      }
                    }}
                  >
                  {/* {active ? "Activate": "Deactivate"} */}
                  {active ? "Deactivate" : "Activate"}
                  </button>
                }
                {blackoutToggle}
              </div>
            </td>
          }
        </tr>
      );

      // if(this.state.units[i].exists){
      rows.push(TableRows);
      // }
    }
    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col lg:flex-row align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full w-min mb-3 lg:mb-0 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                id={"search"}
                onChange={this.handleInputChange}
                className="text-sm searchBox"
                placeholder="Search by unit name"
                value={this.state.search}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>

            {/* <span className="py-1 lg:px-4 font-medium margin text-gray-500 self-start ">
              Filters:
            </span>
            <div className="flex flex-row flex-wrap self-start">
              <button 
                className={
                  (this.state.filter_blackout ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_blackout: !this.state.filter_blackout,
                  })
                }}
              >
                Blackout
              </button>
              {this.props.active && <button
                className={
                  (this.state.filter_issues ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_issues: !this.state.filter_issues,
                  })
                }}
              >
                Issues
              </button>}
            </div> */}
          </div>
          <PageSelector
              curpage={this.state.current_page_number}
              totalpages={this.state.total_pages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 ">ID</th>
              <th className="py-2 ">Suite Name</th>
              <th className="py-2  pl-5">Suite Number</th>
              <th className="py-2  ">Bedrooms</th>
              <th className="py-2  ">Bed Configuration</th>
              <th className="py-2  ">Sofa Bed</th>
              <th className="py-2  ">Is Quadable?</th>
              <th className="py-2  ">Quad Status</th>
              {/* <th className="py-2  ">Type</th> */}
              {/* Book Warnings */}
              {this.props.active && <th className="py-2  "></th>}
              {/* Active Toggle */}
              {this.props.active && <th className="py-2 content-center">Deactivate Unit</th>}
              {/* Create Blackout  */}
              {this.props.active && <th className="py-2  ">Create Blackout</th>}
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={9} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withHooksHOC(withRouter(UnitList));
