import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import EditOptionPanel from "../EditOptionPanel";
import UnitInfo from "./ProfileComponents/UnitInfo";
import UnitFiles from "./ProfileComponents/UnitFiles";
import Label from "../LabelWrap";
import EditUnit from "./EditUnit";
import OwnerContact from "./ProfileComponents/OwnerContact";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { HiPause } from "react-icons/hi";
import NewBlackout from "./NewBlackout";
import CancelModal from "../Bookings/CancelModal";
import FilesToLoad from "./ProfileComponents/FilesToLoad";
import Header from "./InfoSheetComponents/Header";
import Body from "./InfoSheetComponents/Body";
import ViewSheet from "./InfoSheetComponents/ViewSheet";
import { FiPauseCircle } from "react-icons/fi";

type Props = {
  history: any;
  location: any;
};

type State = {
  suite: string;
  suite_number: string;
  suite_address: string;
  suite_building: string;
  suite_type: string;
  min_stay: string;
  heat_building: boolean;
  suite_size: number;
  bath_count: number;
  bed_count: number;
  fob_id: number;
  owner_email: string;
  management_start_date: Date;
  isp: string;
  isp_account: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  rent_due: number;
  rent_increase_date: Date;
  lease_start: Date;
  lease_end: Date;
  last_month_rent_hold: number;
  deposit_hold: number;
  password: string;
  login: string;
  unit_phone: string;
  tvp: string;
  hydro_provider: string;
  notes: string;
  percent_managed: number;
  tv_account: string;
  managed_model: boolean;
  balcony: boolean;
  editToggle: boolean;
  blackoutToggle: boolean;
  unit_id: number;
  bed_config: string;
  unit_owner_id: number;

  isQuadable: boolean;
  quad_status: boolean;

  super_quad: boolean;
  super_quad_bunk: boolean;
  super_quad_notes: string;

  active: boolean;

  sofa_bed: boolean;
};

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

class UnitProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      suite: "",
      suite_number: "",
      suite_address: "",
      suite_building: "",
      managed_model: false,
      percent_managed: 0,
      suite_type: "",
      min_stay: "",
      fob_id: 0,
      owner_email: "",
      management_start_date: new Date(),
      heat_building: false,
      suite_size: 0,
      bath_count: 0,
      bed_count: 0,
      isp: "",
      isp_account: 0,
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      rent_due: 0,
      rent_increase_date: new Date(),
      lease_start: new Date(),
      lease_end: new Date(),
      last_month_rent_hold: 0,
      deposit_hold: 0,
      password: "",
      login: "",
      unit_phone: "",
      tvp: "",
      tv_account: "",
      hydro_provider: "",
      notes: "",
      balcony: false,
      editToggle: false,
      blackoutToggle: false,
      unit_id: -1,
      bed_config: "",
      unit_owner_id: -1,

      isQuadable: false,
      quad_status: false,

      super_quad: false,
      super_quad_bunk: false,
      super_quad_notes: "",
      active: false,
      sofa_bed: false,
    };
  }

  // handleEdit = () => {
  //   alert("opens edit bar for Units");
  // };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (!this.state.active) {
      alert("You cannot edit inactive units");
      return;
    }

    if (toggle) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  handleBlackoutToggle = () => {
    if (this.state.blackoutToggle) {
      this.setState({ blackoutToggle: false });
    } else {
      this.setState({ blackoutToggle: true });
    }
  };

  componentDidMount = async () => {
    // let unitId = store.getState().unitReducer.curId
    let unitId = new URLSearchParams(window.location.search).get("id");
    let unit = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${unitId}`);
    let unitData = unit.data;

    let ownerData = await fetchGetJSON(
      `${Constants.API_PATH_OWNER_GET}${unitData.unit_owner_id}`
    );

    let buildingData = await fetchGetJSON(
      `${Constants.API_PATH_BUILDING_GET}${unitData.building_id}`
    );
    this.setState({
      active: unitData?.active,
      suite: unitData?.suite_name,
      suite_number: unitData?.suite_number,
      suite_address: unitData?.location,
      suite_building: buildingData.data.building_name,
      managed_model: unitData?.type === "Rental" ? false : true,
      percent_managed: unitData?.management_percentage,
      suite_type: unitData?.suite_type,
      min_stay: unitData?.min_stay,
      fob_id: unitData?.fob_id,
      owner_email: ownerData.data.email,
      management_start_date: new Date(2022, 1, 1),
      heat_building: true,
      suite_size: unitData?.square_feet,
      bath_count: unitData?.bathrooms,
      bed_count: unitData?.bedrooms,
      isp: unitData?.internet_provider,
      isp_account: unitData?.internet_account,
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      rent_due: unitData.rent_due,
      rent_increase_date: new Date(unitData?.rent_increase_date),
      lease_start: new Date(unitData?.lease_start_date),
      lease_end: new Date(unitData?.lease_end_date),
      last_month_rent_hold: unitData?.last_month_rent_held,
      deposit_hold: unitData?.deposit_held,
      password: unitData?.internet_password,
      login: unitData?.internet_login,
      unit_phone: unitData?.unit_phone,
      tvp: unitData?.tv_provider,
      tv_account: unitData?.tv_account,
      hydro_provider: unitData?.hydro_provider,
      notes: unitData?.notes,
      balcony: unitData?.balcony,
      editToggle: false,
      unit_id: parseInt(unitId),
      bed_config: unitData.bed_configuration,
      unit_owner_id: unitData.unit_owner_id,

      isQuadable: unitData.isQuadable,
      quad_status: unitData.quad_status,

      super_quad: unitData.super_quad,
      super_quad_bunk: unitData.super_quad_bunk,
      super_quad_notes: unitData.super_quad_notes,

      sofa_bed: unitData.sofa_bed,
    });

    console.log(this.state);
  };

  render() {
    let HeatBuildingTag: any = "";
    let bgFocus;
    let ModelType = "";

    if (this.state.editToggle === true) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    if (this.state.heat_building === true) {
      HeatBuildingTag = (
        <div className=" cursor-default h-6 mt-3 mr-2 bg-red-300 text-red-700 text-xs rounded-full flex flex-row items-center text-center px-5">
          Heat Building
        </div>
      );
    }

    if (this.state.managed_model === true) {
      ModelType = "Managed Model";
    } else {
      ModelType = "Rental Model";
    }

    return (
      <div>
        <NewBlackout
          onoff={this.state.blackoutToggle}
          title={"Would you like to create a new blackout?"}
          handleModalToggle={this.handleBlackoutToggle}
          id={this.state.unit_id}
        ></NewBlackout>
        <div className=" bg-white font-display lg:rounded-tl-lg  ">
          <EditUnit
            toggle={this.state.editToggle}
            handleEditBarToggle={this.handleEditBarToggle}
            unitData={this.state}
          />
          {bgFocus}
          <BreadCrumb
            rootPage={"Units"}
            subPage={this.state.suite}
            sideButton=""
            buttonAction={""}
          />

          <div className="  px-4 pb-20 lg:pb-10 lg:px-10 max-w-screen-xl mx-auto border rounded-lg pt-14 lg:pt-10 bg-white">
            {/* Heading Info Area  */}
            <div className="flex flex-col lg:flex-row mb-0">
              {/* Left Side  */}
              <div className="flex flex-col w-1/2">
                <h1 className="text-2xl px-2 pt-2 text-gray-700 font-black font-display ">
                  {this.state.suite}
                </h1>

                <h2 className="px-2 -mt-0 text-md font-extralight text-gray-500 font-europe">
                  Suite {this.state.suite_number}
                </h2>
                <h2 className="px-2 pt-0 text-sm font-extralight text-gray-400 font-europe">
                  {this.state.suite_address}
                </h2>
                <h2 className="px-2 pt-0 text-sm font-extralight text-gray-400 font-europe">
                  {this.state.suite_building}
                </h2>
                <h2 className="px-2 pt-5 text-sm font-extralight text-gray-600 font-europe">
                  Is the Unit Quadable:{" "}
                  {this.state.isQuadable ? (
                    <span className="text-green-500">Yes</span>
                  ) : (
                    <span className="text-red-500">No</span>
                  )}
                </h2>
                <h2 className="px-2 pt-0 text-sm font-extralight text-gray-600 font-europe">
                  Does the Unit have a Sofa Bed:{" "}
                  {this.state.sofa_bed ? (
                    <span className="text-green-500">Yes</span>
                  ) : (
                    <span className="text-red-500">No</span>
                  )}
                </h2>
                {this.state.isQuadable && (
                  <h2 className="px-2 pt-0 text-sm font-extralight text-gray-600 font-europe">
                    Quad Status of Unit:{" "}
                    {this.state.quad_status ? (
                      <span className="text-green-500">Unit is Quaded</span>
                    ) : (
                      <span className="text-red-500">Unit is NOT Quaded</span>
                    )}
                  </h2>
                )}
                <h2 className="px-2 pt-5 text-sm font-extralight text-gray-600 font-europe">
                  Super Quad:{" "}
                  {this.state.super_quad ? (
                    <span className="text-green-500">Yes</span>
                  ) : (
                    <span className="text-red-500">No</span>
                  )}
                </h2>
                <h2 className="px-2 text-sm font-extralight text-gray-600 font-europe">
                  Super Quad Bunk:{" "}
                  {this.state.super_quad_bunk ? (
                    <span className="text-green-500">Yes</span>
                  ) : (
                    <span className="text-red-500">No</span>
                  )}
                </h2>
                {(this.state.super_quad || this.state.super_quad_bunk) && (
                  <h2 className="px-2 text-sm font-extralight text-gray-600 font-europe">
                    Super Quad notes:{" "}
                    <span className="text-green-500">
                      {this.state.super_quad_notes}
                    </span>
                  </h2>
                )}
              </div>

              {/* Right Side */}
              <div className="flex flex-col lg:flex-row w-full lg:w-1/2 items-start justify-end">
                <div className="flex flex-row mb-4">
                  {HeatBuildingTag}
                  <div className=" cursor-default h-6 mt-3 mr-2 bg-gray-200 text-gray-500  text-xs rounded-full flex flex-row items-center text-center px-5">
                    {" "}
                    {ModelType}{" "}
                  </div>
                </div>
                <div className="w-auto px-4  flex flex-row items-center justify-around shadow-none h-16 rounded-full lg:px-0  lg:rounded-none   lg:shadow-none bg-gray-100  lg:justify-end lg:bg-transparent ">
                  <button
                    onClick={() => this.handleBlackoutToggle()}
                    key={"Blackout"}
                    className="mx-2 group pb-1"
                  >
                    <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
                      Blackout
                    </span>
                    <HiPause className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
                  </button>
                </div>

                <EditOptionPanel
                  handleEditBarToggle={this.handleEditBarToggle} //Used for Edit Toggle
                  parentData={""}
                  panelType="Unit"
                  handleFlag={""}
                  handleState={this.handleToggleChange} //Used for Cancel Modal
                />
              </div>
            </div>

            <TabList warning={false}>
              <Label label="Info">
                <ViewSheet
                  unit_id={this.state.unit_id}
                  name="View Unit Sheet"
                  booking_id={null}
                />
                <UnitInfo UnitData={this.state} />
              </Label>

              <Label label="Owner">
                <OwnerContact
                  history={this.props.history}
                  unitId={this.state.unit_id}
                />
              </Label>

              <Label label="Files">
                <UnitFiles />
              </Label>
            </TabList>
          </div>
        </div>
      </div>
    );
  }
}

export default UnitProfile;
