import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { FiChevronsRight, FiCornerUpRight } from "react-icons/fi";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Modal from "../Modal";

interface Props {
  title: string;
  handleModalToggle: any;
  handleSwapToggle: any;
  handleMultipleToggle: any;
  onoff: boolean;
  ErrorNote: any;
}
interface ModalState {
  title: string;
  onoff: boolean;
  swapOnOff: boolean;
  transfer_date: any;
  building: string;
  building_id: string;
  unit: string;
  buildingsList: any;

  new_rate: number;

  //New Additions to Parking:
  currentBuildingID: number;
  currentUnit: any;
  bookingInfo: any;
  parkingRequired: boolean;
  parkingSpotID: number;
  parkingSpotList: any;

  displayParkingTransfer: boolean;
  activeParkingSpots: any;
  parkingSpotInfo: any;
  parkingIDs: any;

  parkingSwap: boolean;
  swapInfo: any;
  swapParkingSpot: any;

  currentParkingInfo: any;
  currentParkingSpotName:string;

  warning: boolean;
  warningInfo: any;

  ru_reservation_id: number;
  originalUnit: any;

  allUnits:any;
  allBuildings: any;

  rentalsUnitedWarningInfo: string;
}

type Building = {
  id: string;
  name: string;
  units: string[];
}

class TransferModal extends Component<Props, ModalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      swapOnOff: false,
      title: this.props.title,
      onoff: this.props.onoff,
      transfer_date: "",
      building: "",
      building_id: "",
      unit: "",
      buildingsList: [],
      new_rate: 0,
      currentBuildingID: 0,
      bookingInfo: null,
      parkingRequired: false,
      parkingSpotID: 0,
      parkingSpotList: null,
      currentUnit: null,
      displayParkingTransfer: false,
      activeParkingSpots: null,
      parkingSpotInfo: null,
      parkingIDs: [],
      parkingSwap: false,
      swapInfo: null, //Parking Swap
      swapParkingSpot: null,
      currentParkingInfo:null,
      currentParkingSpotName: '',
      warning: false,
      warningInfo: null,

      ru_reservation_id: null,
      originalUnit: null,
      allUnits: null,
      allBuildings: null,

      rentalsUnitedWarningInfo: '',
    };
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = async () => {
    const bookingId = Number(new URLSearchParams(window.location.search).get("id"));

    const bookingInfo = await fetchGetJSON(Constants.API_PATH_BOOKING_GET + bookingId)
    let allUnits = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET_ALL}?active=true`);
    let allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);

    console.log("Loading Data for Transfer Suite")
    if(bookingInfo?.status == "Success") {

      const currentUnit = allUnits.data.filter((unit: any) => {
        return (unit.id == bookingInfo.data[0].unit_id)
      })

      const currentBuilding = allBuildings.data.filter((building:any) => {
        return(
          building.id == currentUnit[0].building_id
        )
      })

      this.setState({
        parkingRequired: bookingInfo.data[0].parking_requested,
        building: currentBuilding[0].building_name,
        building_id: currentBuilding[0].id,
        currentBuildingID: currentBuilding[0].id,
        currentUnit: currentUnit[0].suite_name,
        activeParkingSpots: null,
        ru_reservation_id: bookingInfo.data[0].ru_reservation_id,
        originalUnit: currentUnit[0],
        allUnits: allUnits.data,
        allBuildings: allBuildings.data,
      })

      //Check to see if parking request is true:
      if(bookingInfo.data[0].parking_requested) {
        const parkingBookings = await fetchGetJSON(Constants.API_PATH_PARKING_BOOKING_ID + bookingId)

        let parkingIDs:any = [];
        let parkingSpotIDs:any = [];

        if(parkingBookings.status == "Success") {
          for(const parking of parkingBookings?.data) {
            if(!parking.cancelled) {
              parkingIDs.push(parking.id)
              parkingSpotIDs.push(parking.parking_spot_id)
            }
          }
          this.setState({
            parkingIDs: parkingIDs,
            currentParkingInfo: parkingBookings?.data[0]
          })
  
          //Set Active Parking Spots
          const allActiveParkingSpots = await fetchGetJSON(Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE);
          const activeParkingSpots = allActiveParkingSpots.data.filter((parkingSpot:any) => !parkingSpotIDs.includes(parkingSpot.id)).sort((parkingSpotOne:any, parkingSpotTwo:any) => parkingSpotOne.parking_spot_location.localeCompare(parkingSpotTwo.parking_spot_location))
          console.log(parkingSpotIDs)
          console.log(activeParkingSpots)

          const parkingSpotName = allActiveParkingSpots.data.filter((parkingSpot:any) => {
            return parkingSpot.id == parkingBookings.data[0].parking_spot_id
          })
          
          this.setState({
            activeParkingSpots: activeParkingSpots,
            parkingSpotID:  activeParkingSpots[0].id,
            currentParkingSpotName: `${parkingSpotName[0].parking_spot_location} | ${parkingSpotName[0].parking_spot_level}`,
          })
        }
      }
    }

    const buildingListFinal = allBuildings.data.map((building: any) => {
      const currentUnit = allUnits.data.filter((unit: any) => unit.building_id == building.id)
      return {
        name: building.building_name,
        id: building.id,
        units: currentUnit
      }
    })
    buildingListFinal.sort((a: any, b: any) => a.name.localeCompare(b.name))
    this.setState({ buildingsList: buildingListFinal })
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff })
    }
  };

  handleSwapModal = (event: any) => {
    this.setState({ swapOnOff: !this.state.swapOnOff })

  };

  confirmSwapModal = (event: any) => {
    console.log("click!")
  };

  transferBooking = async (transferBody: any) => {
    return await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BOOKING_TRANSFER,
      transferBody
    );
  };

  // Find proper unit and building to transfer booking
  //  If there are any questions with this whole component, contact Newman. This whole component needs to be redone anyways
  handleClick = async (event: any) => {
    event.preventDefault();

    if(this.state.warning) {
      this.props.handleModalToggle()
      return
    }

    const isTransfer = this.state.title == "Post Check-In Transfer"
    let NotificationHandler = this.props.ErrorNote
    const bookingId = Number(new URLSearchParams(window.location.search).get("id"));
    const allUnits = this.state.allUnits;
    const allBuildings = this.state.allBuildings;

    const unitData = allUnits.find((unitItem: any) => unitItem.suite_name === this.state.unit);
    const buildingData = allBuildings.find((buildingItem: any) => buildingItem.building_name === this.state.building);

    if (!unitData) {
      NotificationHandler("Error", "Unit not found");
      return;
    }

    if (!buildingData) {
      NotificationHandler("Error", "Building not found");
      return;
    }

    if (!this.state.transfer_date && isTransfer) {
      NotificationHandler("Error", "Please fill in transfer date");
      return;
    }

    if (unitData.building_id !== buildingData.id) {
      NotificationHandler("Error", "Unit does not belong to proper building");
      return;
    }


    //Call transfer route or pretransfer route
    console.log("Inside Transfer Modal: ")
    console.log(isTransfer)

    //Need to handle Rentals United Pre Transfer, only allow for Pre-Transfer to same listing ID, if not then disallow:
    console.log(this.state.ru_reservation_id)
    console.log(this.state.originalUnit)
    console.log(unitData)
    if(this.state.ru_reservation_id && (this.state.originalUnit.rentals_united_listing_id != unitData.rentals_united_listing_id)) {
      NotificationHandler("Error", "Error", "This is a Rentals United Booking. You can only move this unit to the same suite type of the same building in the Admin System.");
      return;
    }

    //PreTransfer for Parking
    if((this.state.buildingsList.find((building: Building) => building.name == event.target.value)?.id != this.state.currentBuildingID) && this.state.displayParkingTransfer && !this.state.parkingSwap && !isTransfer) {
      console.log("Parking Pre Transfer")
      if(this.state.parkingIDs.length == 1) {
        for(const id of this.state.parkingIDs) {
          const parkingPreTransfer = 
          // isTransfer ? await fetchRequestJSON('POST', Constants.API_PATH_PARKING_TRANSFER, {
          //   id: id,
          //   new_parking_spot: this.state.parkingSpotID,
          //   transferDate: this.state.transfer_date
          // }) :
          await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_PRETRANSFER, {
            id: id,
            new_parking_spot: this.state.parkingSpotID
          }) 
  
          if(parkingPreTransfer.status == "Success")  {
            NotificationHandler("Success",  `Successfully Transferred Parking`)
          } else if (parkingPreTransfer.status == "Swap") {
            NotificationHandler("Info", "One conflicting parking found, Swap Eligible")
            

            this.setState({
              parkingSwap: true,
              swapParkingSpot: parkingPreTransfer.data.parkingSpotName,
              swapInfo: parkingPreTransfer.data.swapParking[0]
            })
  
            return
          } else if (parkingPreTransfer.status == "Error: multiple parkings") {
            NotificationHandler('Warning', "Multiple Conflicting Bookings Found, Swap Not Eligible");

            this.setState({
              warning: true,
              warningInfo: parkingPreTransfer.data.parkingSpotCheck,
              swapParkingSpot: parkingPreTransfer.data.parkingSpotName,
            })
            return
          }  else if (parkingPreTransfer.status == "Error: Currently Occupied Parking Spot") {
            NotificationHandler("Warning", `Conflicting Occupied Parking Found, Swap Not Eligible`);
            this.setState({
              warning: true,
              warningInfo: parkingPreTransfer.data.currentOccupiedParkings,
              swapParkingSpot: parkingPreTransfer?.data?.parkingSpotName,
            })
            return;
          } else {
            NotificationHandler('Error', parkingPreTransfer.status);
            return 
          }
        }
      } else {
        NotificationHandler('Info', `Multiple Parkings Detected for PreTransfer for Booking, reminder to pretransfer manually in their respected parking profiles`);
      }
    } else if(this.state.parkingSwap) {
      const transferParking =  await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SWAP, {
        first_parking_id: this.state.parkingIDs[0],
        second_parking_id: this.state.swapInfo.id
      })

      if (transferParking.status) {
        if(transferParking.status == "Success") {
          NotificationHandler("Success", 'Success', `Successfully swapped Parking ${transferParking.data.firstParking.updatedFirstParking.id} and ${transferParking.data.secondParking.updatedSecondParking.id}`);
          
        } else if (transferParking.status == "Multiple Parkings Error") {
          NotificationHandler("Error", "Error", "Multiple Parkings Detected, Parking Swap Unsuccessful | No Transfer Applied")
          this.setState({
            warningInfo: transferParking.swapEligibility,
            warning: true,
            swapParkingSpot: transferParking?.data?.parkingSpotName,
          })
          return
        } else {
          NotificationHandler("Error", "Error", "Error detected during Swap. Please contact dev team for assistance.")
          return 
        }
      } else {
        //This is if status 500
        NotificationHandler('Error', 'Error', 'Contact Dev Team. Swap Unsuccesful')
      }
    }
    
    const transferBookingStatus = isTransfer ? await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BOOKING_TRANSFER, {
      id: bookingId,
      unit_id: unitData.id,
      new_rate: this.state.new_rate,
      start_date: this.state.transfer_date,
      parkingTransfer: this.state.displayParkingTransfer,
      newParkingSpotID: this.state.displayParkingTransfer ? this.state.parkingSpotID : null,
    }) : await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BOOKING_PRETRANSFER, {
      id: bookingId,
      unit_id: unitData.id,
    })

    if (transferBookingStatus.status) {
      this.props.handleModalToggle();
      if (transferBookingStatus.status == "Success") {
        const transferDate = new Date(this.state.transfer_date).toUTCString().substring(0, 17)
        NotificationHandler("Success", `Successfully Transferred Booking #${bookingId} to ${unitData.suite_name} ${isTransfer ? `on ${transferDate}` : ""}`);
      }
      else if (transferBookingStatus.status == "Swap") {
        console.log("Inside SWAP Condition for Pre Transfer")
        const swapBooking = transferBookingStatus.data.unitBookings[0]
        // NotificationHandler("Info", transferBookingStatus.data);
        NotificationHandler("Info", "One conflicting booking found, Swap Eligible");
        this.props.handleSwapToggle(swapBooking.id, this.state.unit, swapBooking.check_in, swapBooking.check_out, swapBooking.check_in);
      }
      else if (transferBookingStatus.status == "Error: multiple bookings") {
        const swapBookings = transferBookingStatus.data.unitBookings
        // NotificationHandler("Info", transferBookingStatus.data);
        const message = `Multiple conflicting bookings found in ${this.state.unit}. \nSwapping is not eligible for more than one conflicting booking.`
        NotificationHandler("Info", "Multiple Conflicting Bookings Found, Swap Not Eligible");
        this.props.handleMultipleToggle(swapBookings, this.state.unit, message);
      }
      else if (transferBookingStatus.status == "Error: Current Occupied Unit") {
        const currentOccupiedBookings = transferBookingStatus.data.currentOccupiedBooking
        const message = `Conflicting booking found in ${this.state.unit} and is already occupied. \nSwapping is not eligible for occupied bookings.`
        NotificationHandler("Info", `Conflicting Occupied Bookings Found, Swap Not Eligible`);
        this.props.handleMultipleToggle(currentOccupiedBookings, this.state.unit, message);
      }
      else {
        NotificationHandler("Error", transferBookingStatus.status);
        // this.props.handleSwapToggle(bookingId, this.state.unit, this.state.transfer_date, this.state.transfer_date, this.state.transfer_date);
      }
    }
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
    if (event.target.id == "building") {
      this.setState({
        building_id: this.state.buildingsList.find((building: Building) => building.name == event.target.value)?.id
      });

      if((this.state.buildingsList.find((building: Building) => building.name == event.target.value)?.id != this.state.currentBuildingID)) {
        console.log("Selecting parking suite transfer")
        //If the building changes, and parking required is true
        // We want to allow to display parking transfer if booking ID is possible
        if(this.state.parkingRequired) {
          this.setState({
            displayParkingTransfer: true,
          }) 
        }
        this.setState({
          currentUnit: 'No Unit Selected'
        })
      } else {
        if(this.state.parkingRequired) {
          this.setState({
            displayParkingTransfer: false,
          }) 
        }
      }

    } else if (event.target.id == "unit") {
      this.setState({
        currentUnit: event.target.value
      })

      //If this is a rentals united booking, we must indicate to users if the selected suite does not share the same listing id
      const unitData = this.state.allUnits.find((unitItem: any) => unitItem.suite_name === event.target.value);

      if(unitData) {
        if(this.state.ru_reservation_id && (unitData.rentals_united_listing_id != this.state.originalUnit.rentals_united_listing_id)) {
          this.setState({
            rentalsUnitedWarningInfo: `The current selected unit contains a different listing id to the original unit the booking belongs to`
          })
        } else {
          this.setState({
            rentalsUnitedWarningInfo: ""
          })
        }
      }
    }
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  render() {
    const isTransfer = this.state.title == "Post Check-In Transfer"
    const displayParkingTransfer = this.state.displayParkingTransfer;

    if (!this.props.onoff) {
      return null;
    }
    let swapModal = <></>;
    if (this.state.swapOnOff) {
      swapModal = (
        <Modal
          title="Delete Booking"
          content="Would you like to delete this booking?"
          onoff={this.state.swapOnOff}
          handleModalToggle={this.handleSwapModal}
          confirmButton={this.confirmSwapModal}
        ></Modal>
      );
    }

    let inactive = "";
    let availableUnits = [];
    if (this.state.building === "") {
      inactive = "opacity-50 pointer-events-none ";
    } else {
      availableUnits = this.state.buildingsList.find((building: any) => building.id == this.state.building_id)?.units
      console.log(availableUnits)
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
      >
        {swapModal}
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          {isTransfer ? <FiCornerUpRight className="text-gray-600 text-3xl mb-3" /> : <FiChevronsRight className="text-gray-600 text-3xl mb-3" />}
          <span className="text-xl font-bold text-gray-800 text-left">{this.state.title}</span>
          {
            this.state.ru_reservation_id &&
            <span className="text-m font-bold text-green-400 text-left">This is a Rentals United Booking. Only Tranfers to the same suite type in the same building is allowed.</span>
          }
          {
            this.state.ru_reservation_id &&
            <span className="text-m mt-2 text-blue-400 text-left">Note: Only Gerrard and Studio do not share the same suite type between buildings. You can transfer with the same suite type for combined buildings (example: MLS)</span>
          }

          {/* <span className="text-xs text-center text-gray-400"></span> */}
          <form>
            <div className="grid grid-cols-3 gap-6 w-full my-5">
              {isTransfer && <label className="block  ">
                <span className="text-gray-400 text-xs">Date of Transfer:</span>
                <input
                  id="transfer_date"
                  onChange={this.handleDateChange}
                  value={inputISO(this.state.transfer_date)}
                  type="date"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>}

              <label className="block ">
                <span className="text-gray-400 text-xs">Building:</span>
                <select
                  id="building"
                  onChange={this.handleInputChange}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="Choose"
                  value={this.state.building}
                >
                  <option selected disabled hidden></option>
                  {this.state.buildingsList.map((building: Building) =>
                    <option value={building.name}>{building.name}</option>
                  )}
                </select>
              </label>

              <label className={`${inactive} block  `}>
                <span className="text-gray-400 text-xs">Unit</span>
                <select
                  id="unit"
                  onChange={this.handleInputChange}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="Choose"
                  value={this.state?.currentUnit}
                >
                  <option selected disabled hidden></option>
                  <option value={'No Unit Selected'}>--Select a Unit--</option>
                  {availableUnits && availableUnits.map((unit: any) =>
                    <option value={unit.suite_name}>{this.state.ru_reservation_id ? `${unit.suite_name} Listing ID: ${unit.rentals_united_listing_id}`: unit.suite_name}</option>
                  )}
                </select>
              </label>

              {isTransfer && <label className=" block  ">
                <span className="text-gray-400 text-xs">New Monthly Rate</span>
                <input
                  id="new_rate"
                  onChange={this.handleInputChange}
                  onWheel={(e) => { e.currentTarget.blur() }}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  type="number"
                  value={this.state.new_rate}
                >
                </input>
              </label>}

            </div>
            {
                displayParkingTransfer && 
                <>
                  <span className="text-xl font-bold text-gray-800 text-left">{isTransfer? `Parking Transfer` : `Parking Pre-MoveIn Transfer`}</span>
                  {
                    !this.state.warning ? 
                    <p className="text-xs text-gray-400">Parking Transfer will only be included if the booking's suite transfer is to a new building</p> :
                    <p className="text-s text-red-500">
                      Unable to continue with transfer. <u>Resolve Parking Spot Conflict.</u>
                    </p>
                  }

                  {
                    this.state.parkingIDs.length > 1 &&
                    
                    <div>
                      Multiple parkings detected for this booking. Please manually transfer these parkings: 
                        {
                          this.state.parkingIDs.map((parkingID:number) => {
                            <a href={`/user/parkings/parkingprofile/?id=${parkingID}`} className="text-green-500" target="_blank">
                              Parking ID #{parkingID}
                            </a>
                          })
                        }
                    </div>
                  }
                  {
                    (!this.state.parkingSwap && this.state.parkingIDs.length == 1 && !this.state.warning) &&
                    
                    <div className="grid grid-cols-3 gap-6 w-full my-3">
                      {isTransfer && <label className="block  ">
                        <span className="text-gray-400 text-xs">Date of Transfer:</span>
                        <input
                          id="transfer_date"
                          onChange={this.handleDateChange}
                          value={inputISO(this.state.transfer_date)}
                          type="date"
                          className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                          placeholder="Select the Transfer Date"
                        />
                      </label>}

                      <label className="block w-96">
                        <span className="text-gray-400 text-xs">Parking Spot:</span>
                        <select
                          id="parking spot"
                          onChange={(input) => this.setState({
                            parkingSpotID: +input.target.value
                          })}
                          className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                          placeholder="Choose"
                          // value={newParkingSpot}
                        >
                          {this.state.activeParkingSpots?.map((parkingSpot:any) =>
                            <option value={parkingSpot.id}>{parkingSpot.parking_spot_location} | {parkingSpot.parking_spot_level}</option>
                          )}
                        </select>
                      </label>
                    </div>
                  }
                  {
                    (this.state.parkingSwap && !this.state.warning) &&
                    <div className="flex flex-row gap-40 w-full my-5 ml-10 ">
                      <label className="flex flex-col space-y-5 ">
                          <span className="text-gray-800 text-m font-bold">First Parking Id: {this.state.parkingIDs[0]}</span>
                          <div className="text-gray-400 text-xs">Start Date: <span className="text-gray-800 text-xs">{this.state.currentParkingInfo.start_date}</span></div>
                          <div className="text-gray-400 text-xs">End Date: <span className="text-gray-800 text-xs">{this.state.currentParkingInfo.end_date}</span></div>
                          <div className="text-gray-400 text-xs">Parking Spot: <span className="text-gray-800 text-xs">{this.state.currentParkingSpotName}</span></div>
                      </label>
                  
                      <label className="flex flex-col space-y-5 mr-5">
                          <span className="text-gray-800 text-m font-bold">Second Parking Id: {this.state.swapInfo.id}</span>
                          <div className="text-gray-400 text-xs">Start Date: <span className="text-gray-800 text-xs">{this.state.swapInfo.start_date}</span></div>
                          <div className="text-gray-400 text-xs">End Date: <span className="text-gray-800 text-xs">{this.state.swapInfo.end_date}</span></div>
                          <div className="text-gray-400 text-xs">Parking Spot: <span className="text-gray-800 text-xs">{this.state.swapParkingSpot}</span></div>
                      </label>
                    </div >
                  } 
                  {
                    this.state.warning &&
                    <div className="flex flex-row gap-40 w-full my-5 ml-10">
                      {this.state.warningInfo.map((parking:any, index:number) => {
                          return (
                              <label className="flex flex-col space-y-5 ">
                                  <span className="text-gray-800 text-m font-bold">{index == 0 ? "Current Occupied Parking: " : ""}Parking Id: {parking.id}</span>
                                  <div className="text-gray-400 text-xs">Check in: <span className="text-gray-800 text-xs">{parking.start_date}</span></div>
                                  <div className="text-gray-400 text-xs">Check out: <span className="text-gray-800 text-xs">{parking.end_date}</span></div>
                                  <div className="text-gray-400 text-xs">Parking Spot Name: <span className="text-gray-800 text-xs">{this.state.swapParkingSpot}</span></div>
                              </label>
                          )
                      })}
                    </div >
                  }
                </>
            }
            {
              this.state.rentalsUnitedWarningInfo && (
                <label className="flex flex-col space-y-5">
                  <span className="text-red-500 text-m font-bold">
                    {this.state.rentalsUnitedWarningInfo}
                  </span>
                </label>
              )
            }
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={
                  this.handleClick

                }
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                {this.state.parkingSwap && !this.state.warning ? 'Swap Parking' : this.state.warning ? 'End' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(TransferModal);