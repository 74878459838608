import { useEffect } from "react";
import { Button } from "@mui/material";
import { MdOpenInNew } from "react-icons/md";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}

type Props = {
  booking_id?: number,
  unit_id?: number,
  name?: string,
}

const ViewSheet = (props: Props) => {
  const openInNewTab = (url: string) => {
    console.log(url);
    window.open(url, "_blank");
  };

  const captureId = async () => {
    let url = props.booking_id 
      ? await fetchGetJSON(`${Constants.API_PATH_BOOKING_PORTAL_URL}${props.booking_id}`) 
      : await fetchGetJSON(`${Constants.API_PATH_GET_UNIT_DATA_URL}${props.unit_id}`);
    
    // Check if the URL is a string and log it
    console.log(url);

    openInNewTab(url.url);
  };

  useEffect(() => {
    // console.log("Inside view sheet");
    // console.log(`Props: ${props.url}`);
    // console.log(`Unit Id: ${props.id}`)
  }, []);

  return (
    <div className="mt-4 mb-4">
        <Button
          variant="outlined"
          color="forestGreen"
          endIcon={<MdOpenInNew />}
          disableElevation
          onClick={() => captureId()}
        >
          {props.name ?? 'View Sheet'}
        </Button>
    </div>
  );
};

export default ViewSheet;
