import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RiUser3Fill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";
import Tooltip from "../../Tooltip";
import UnitBubble from "../../UnitBubble";
import ToolTipAlt from "../../ToolTipAlt";
import { MdSearch } from "react-icons/md";
import { fetchGetJSON, fetchRequestJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { addDays } from "../../../utils/DateFormat";
import { withHooksHOC } from "../../Notifications/NotificationProvider";

type Props = {
  history: any;
  ErrorNote: any;
};

type Unit = {
  id: number;
  suite_name: string;
};

type Tenant = {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
};

type CheckInTableState = {
  Bookings: any,
  search: string
};

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)


class CheckInTable extends Component<
  Props & RouteComponentProps, CheckInTableState
> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      Bookings: [],
      search: ""
    };
  }

  async componentDidMount() {
    let allBookings = await fetchGetJSON(`${Constants.API_PATH_GET_BOOKING_CHECK_IN}?search=${this.state.search}`);
    if (allBookings.status !== "Success") {
      const notificationHandler = this.props.ErrorNote;
      notificationHandler("Error", "Error getting check-in bookings", "");
      this.setState({
        Bookings: [],
      });
    } else {
      this.setState({
        Bookings: allBookings.bookingSet,
      });
    }
  }

  HandleRowClick = (bookingID: string) => {
    const url = `${window.location.origin}/user/bookings/bookingprofile?id=${bookingID}`
    window.open(url, "_blank")
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  updateList = async () => {
    const body = {
      search: this.state.search.trim().replaceAll(' ', '%')
    };

    const allBookings = await fetchGetJSON(`${Constants.API_PATH_GET_BOOKING_CHECK_IN}?search=${body.search}`);
    console.log(allBookings)
    if (allBookings.status !== "Success") {
      const notificationHandler = this.props.ErrorNote;
      notificationHandler("Error", "Error getting check-in bookings", "");
      this.setState({
        Bookings: [],
      });
    } else {
      this.setState({
        Bookings: allBookings.bookingSet,
      });
    }
  }

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }


  getCheckInDateForBooking(targetBooking:any) {
    // Javascript moves the date back by one when creating the date from a string (I think it has to do with timezones)
    // Moving date forward to obtain the correct date.
    // UPDATE: this is because of the date from postgre being UTC rather than EST, must convert properly

    let year = "1997"
    let month = "04"
    let day = "23"

    let isToday = false;
    if(targetBooking?.check_in)
    {
      let bookingCheckInDate = new Date(targetBooking?.check_in);
      bookingCheckInDate = addDays(bookingCheckInDate, 1);
      year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(bookingCheckInDate);
      month = new Intl.DateTimeFormat("en", { month: "long" }).format(bookingCheckInDate);
      day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(bookingCheckInDate);
      let hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(bookingCheckInDate);
      if (hour === "12 AM") {
        hour = "No Time Specified";
      }
    }

    if(dayjs(targetBooking.check_in).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD')) {
      isToday = true;
    }

    return { year, month, day, isToday };
  }

  toCheckInForm = (bookingID: string) => {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: "/User/Check Ins/BookingId",
        search: `id=${bookingID}`
      })
    }
  };


  render() {
    let rows = [];

    let index = 0;
    for (let booking of this.state.Bookings) {
      if (booking.cancelled) {
        continue;
      }

      let altBg = '';
      if (index % 2 != 1) {
        altBg = "font-bold";
      } else {
        altBg = '';
      }

      // let blackouts = this.getBlackoutsForUnit(booking.unit);
      const { year, month, day, isToday } = this.getCheckInDateForBooking(booking);

      let isTodayFont = 'text-gray-600';
      if(isToday){
        isTodayFont = 'text-green-500'
        altBg = 'font-bold'
      } 

      let parkingIndicator = <></>;
      parkingIndicator = <span className={`px-8 lg:px-9 py-2 rounded-md font-bold shadow-sm mr-3 text-${booking.parking_requested ? "green" : "red"}-500`}>P</span>;

      let keyStatus = <></>;
      keyStatus = <span className={`font-bold mx-1 text-${booking.keys_confirm ? "green" : "red"}-500`}>K</span>;

      let cleaningStatus = <></>;
      cleaningStatus = <span className={`font-bold mx-1 text-${booking.cleaning_confirm ? "green" : "red"}-500`}>C</span>;

      let paymentStatus = <></>;
      paymentStatus = <span className={`font-bold mx-1 text-${booking.payment_confirm ? "green" : "red"}-500`}>$</span>;

      let row = (
        <tr className=" border-b grid grid-cols-1  lg:table-row cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full  ">
          {/* TODO: Consider removing the w-full attribute to make widths of columns resizable. */}
          <td
            onClick={() => this.HandleRowClick(booking.id.toString())}
            className="font-light text-sm text-gray-500 border-r-2 border-gray-400"
          >
            {/* TODO: Consider removing the text-gray-500 style sheet since the parent selector already has it */}
            {/* TODO: Consider removing the border-gray-400 style sheet since the parent selector already has it? */}
            {/* TODO: Consider changing border-r-2 to border-r-1 to make border width thinner. */}
            <div className="flex flex-row pr-8">
              {/* TODO: Consider removing the flex-row prperty since the parent selector already has it */}
              <ToolTipAlt message={(booking.corporate_id) ? "Corporate Client" : "Retail Booking"} position="bottom">
                <div className="bg-gray-200 rounded-sm w-12 h-12 shadow-sm flex flex-col justify-center items-center">
                  {(booking.corporate_id) ? <FaUserTie className="text-xl" /> : <RiUser3Fill className="text-xl" />}
                </div>
              </ToolTipAlt>

              <div className="px-4 flex flex-col ">
                <span className="text-base text-gray-600 font-semibold truncate h-7 w-36 py-1">
                  {/* TODO: Consider removing the w-36 property since it has no effect. */}
                  {`${booking?.tenant?.first_name} ${booking?.tenant?.last_name}`}
                </span>
                <span className="text-xs text-gray-400 font-thin h-5">
                  {booking?.tenant?.email}
                </span>
              </div>

            </div>
          </td>

          <td
            onClick={() => this.HandleRowClick(booking.id.toString())}
            className={" px-3 flex-col font-sofia border-r-2 border-gray-400 h-12 w-1/12"}
          >
            {/*  */}
            <Tooltip
              content={<UnitBubble suite_number={booking?.unit?.suite_number} blackout={false} heat={true} />}
              direction=""
              delay=""
              checkList={true}
            >
              <div
                id="bookingConfirmed"
                className="  cursor-pointer text-white font-medium text-xs py-1 flex items-center px-5 my-3"
              >
                {booking?.unit?.suite_name}{" "}
              </div>
            </Tooltip>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-col font-sofia border-r-2 border-gray-400 my-3 ml-3 h-12">
              <span className={altBg + " text-sm h-6 " + isTodayFont} >{`${month} ${day}`}</span>
              <span className={(isToday ? "text-green-400" : "text-gray-400") + " text-sm h-6"}>{year}</span>
            </div>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-row justify-center font-sofia border-r-2 border-gray-400 my-3 ml-3">
              <span className="text-lg text-gray-600">{(booking.check_in_notes) ? booking.check_in_notes : "No Notes"}</span>
            </div>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-row justify-center border-gray-400 my-3 ml-3 ">
              <span className="text-sm font-bold text-gray-600 h-6">{booking?.corporate?.payment_terms ? booking.corporate.payment_terms : 0 }</span>
            </div>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-row justify-center border-gray-400 my-3 ml-3 ">
              <span className="text-sm font-bold text-gray-600 h-6">{parkingIndicator}</span>
            </div>
          </td>

          <td>
            <div onClick={() => this.toCheckInForm(booking.id.toString())} className=" scale-in-ver-top flex flex-row justify-center ">
              <button
                className="transition duration-200 hover:text-green-400 px-9 lg:px-5 py-1 rounded-md text-green-500 font-bold shadow-sm mr-100"
              >
                Check In Form
              </button>
            </div>
          </td>
          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <span className="flex-row justify-center my-3 ml-4">
              <span className="text-sm font-bold h-6 lg:px-5 py-2 rounded-md shadow-sm">{keyStatus} {cleaningStatus} {paymentStatus}</span>
            </span>
          </td>
        </tr>
      );

      rows.push(row);
      index++;
    }

    return (
      <div className=" font-display ">
        <div className="flex flex-row w-full mt-6 mb-5">
          {/* Left Side */}
          <div className="flex flex-col lg:flex-row w-full align-center items-start lg:items-center mb-2">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full lg:w-1/3 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1"/>
              <input
                id={"search"}
                onChange={this.handleInputChange}
                className="text-sm searchBox w-full"
                placeholder="Search booking ID, tenant name, or tenant email"
                value={this.state.search}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" 
              onClick={() => { this.updateList() }}
            >
              Search
            </button>
          </div>
        </div>

        <table className="table-auto w-full mb-8 ">
          <thead>
            <tr className="hidden lg:table-row text-left text-gray-500 border-b border-gray-500 text-center">
              {/* TODO: Consider centering the text with the text-left property. */}
              <th className="py-4 w-12 font-thin ">Full Name</th>
              <th className="py-3 w-auto  font-thin ">Unit</th>
              <th className="py-3 w-32 font-thin ">Check In</th>
              <th className="py-3 font-thin ">Check In Notes</th>
              <th className="py-3 w-auto  font-thin ">Payment Terms</th>
              <th className="py-3 w-32 font-thin ">Parking</th>
              <th className="py-3 font-thin "> Check In Form </th>
              <th className="py-3 w-32 font-thin ">Light Status</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(withHooksHOC(CheckInTable));
