import { useEffect, useState } from "react";
import { LoadingGeneral } from "../../../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../../../utils/PageSelector";
import { MdArrowDropDown, MdArrowDropUp, MdSearch } from "react-icons/md";
import store from "../../../../redux/store"
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider";
import { localRoute } from "../../../../api-requests/api-gateway";

type Props = {
    history: any;
    location: any;
}

enum SortBy {
    ID_DESC = 'id DESC',
    ID_ASC = 'id ASC',
    STARTDATE_DESC = 'start_date DESC',
    STARTDATE_ASC = 'start_date ASC',
    ENDDATE_DESC = 'end_date DESC',
    ENDDATE_ASC = 'end_date ASC'
}

// mapping for filter buttons
const sortButtons = (sortButtonClick: () => void) => ({
    unselectedDesc: <MdArrowDropDown size={32} className={"text-gray-500 hover:text-green-400"} onClick={sortButtonClick} />,
    selectedDesc: <MdArrowDropDown size={32} className={"text-green-500 hover:text-green-400"} onClick={sortButtonClick} />,
    selectedAsc: <MdArrowDropUp size={32} className={"text-green-500 hover:text-green-400"} onClick={sortButtonClick} />,
});

//Dayjs Logic, implement this as a helper function when we refactor
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

function ParkingList(props:Props) {
    //Table State Variables 
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false)
    const [settingId, setSettingId] = useState(-1);

    const [authorization, setAuthorization] = useState(true);

    //Page Selector State Variables
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    
    const [search, setSearch] = useState('');
    const [searchByParkingLocation, setSearchByParkingLocation] = useState(false);
    const [sortBy, setSortBy] = useState(SortBy.ID_DESC)
    const [idSortButton, setIdSortButton] = useState(sortButtons(() => {}).selectedDesc)
    const [startDateSortButton, setStartDateSortButton] = useState(sortButtons(()=>{}).unselectedDesc)
    const [endDateSortButton, setEndDateSortButton] = useState(sortButtons(()=>{}).unselectedDesc)

    //Parking
    const [parkingList, setParkingList] = useState([]);

    const sortButtonMapping: {[key in SortBy]: any} = {
        [SortBy.ID_DESC]: {
            buttonSetter: setIdSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.ID_ASC)).selectedDesc,
        },
        [SortBy.ID_ASC]: {
            buttonSetter: setIdSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.ID_DESC)).selectedAsc,
        },
        [SortBy.STARTDATE_DESC]: {
            buttonSetter: setStartDateSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.STARTDATE_ASC)).selectedDesc,
        },
        [SortBy.STARTDATE_ASC]: {
            buttonSetter: setStartDateSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.STARTDATE_DESC)).selectedAsc,
        },
        [SortBy.ENDDATE_DESC]: {
            buttonSetter: setEndDateSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.ENDDATE_ASC)).selectedDesc,
        },
        [SortBy.ENDDATE_ASC]: {
            buttonSetter: setEndDateSortButton,
            selectedState: sortButtons(()=>setSortBy(SortBy.ENDDATE_DESC)).selectedAsc,
        },
    }

    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
            type: type.toUpperCase(),
            title: title,
            message: message
        })
    }
    //-----------------------
    
    useEffect(() => {
        updateList();

        //Store update list in redux later
        store.dispatch({
            type: "parkingListUpdate",
            listUpdate: updateList,
        });
    }, [])

    //Table Filters:
    const pageUp = async () => {
        let page = currentPageNumber + 1;
        if(page > totalPages) {
            page -= 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const pageDown = async () => {
        let page = currentPageNumber - 1;
        if(page == 0) {
            page += 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const setPerPage = async(val:number) => {
        setRowsPerPage(val)
        setLoaded(false)
        await updateList(currentPageNumber, val)

        if(currentPageNumber > totalPages) {
            setCurrentPageNumber(totalPages)
            updateList(totalPages, val)
        }
    }

    // function for determining what state each filter button is in, therefore deciding the component to render
    const setSortButtons = () => {
        setIdSortButton(sortButtons(() => setSortBy(SortBy.ID_DESC)).unselectedDesc);
        setStartDateSortButton(sortButtons(() => setSortBy(SortBy.STARTDATE_DESC)).unselectedDesc);
        setEndDateSortButton(sortButtons(() => setSortBy(SortBy.ENDDATE_DESC)).unselectedDesc);

        const { buttonSetter, selectedState } = sortButtonMapping[sortBy];
        buttonSetter(selectedState);
    }

    const updateList = async(pageNumber?:number, totalPagesChange?:number) => {
        let filters = {
            name: search,
            search_by_parking_name: !searchByParkingLocation
        }

        const allParking = await fetchGetJSON(encodeURI(`${Constants.API_PATH_PARKING_LIST}?name${search ? `=${search}` : ''}&search_by_parking_name=${!searchByParkingLocation}&page=${pageNumber ? pageNumber : currentPageNumber}&perpage=${totalPagesChange ? totalPagesChange : rowsPerPage}&sortBy=${sortBy}`))
        if(allParking.status !== "Success") {
            setLoaded(true);
            setAuthorization(allParking.status == "Error Unauthorized" ? false : true)
            return;
        }

        setTotalPages(allParking.data.pages)
        setParkingList(allParking.data.rows)
        // tableRows();
        setIsLoading(false);
        setLoaded(true);
    }

    useEffect(() => {
        setSortButtons();
        updateList();
    }, [sortBy])

    const handleInputChange = (event: { target: { id: string; value: string; }; }) => {
        const { id, value} = event.target;
        if(id == 'search') {
            setSearch(value);
        }
    }

    const handleRowClick = async(parkingID:number) => {
        store.dispatch({ type: "parkingIdTransfer", id: parkingID});
        const { location, history } = props;
        console.log(props)
        if (history)
          history.push({
            pathname: `${history.location.pathname}/parkingprofile/`,
            search: `id=${parkingID}`,
          });
    }

    const handleKeyDown = (event:any) => {
        if(event.key == 'Enter') {
            setSortBy(SortBy.ID_DESC)
            updateList()
        }
    }

    const tableCreation = () => {
        return (
            <tbody>
                {
                    parkingList.length != 0 &&  //Weird how rows.length doesn't work but this works, as rows.length by itself indicates if the array is empty or not
                    parkingList.map((parking) => {
                        let status = <></>
                        if(parking.confirmed && !parking.cancelled) {
                            status = (
                                <span className="bg-blue-500 py-2 px-2 text-center rounded-full text-white">
                                    Confirmed
                                </span>
                            )
                        } else if(parking.cancelled){
                            status = (
                                <span className="bg-red-500 py-2 px-2 text-center rounded-full text-white">
                                    Cancelled
                                </span>
                            )
                        }

                        return (
                            <tr className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full">
                                <td
                                    className={
                                    " flex flex-row justify-center lg:table-cell  w-auto lg:w-16 px-1 py-4"
                                    }
                                >
                                    <h3 className="text-indigo-500"> {parking.id}</h3>
                                </td>
                                <td
                                    className={
                                    " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
                                    }
                                    onClick={() => handleRowClick(parking.id)}
                                >
                                    <h3> {parking?.tenant?.first_name} {parking?.tenant?.last_name}</h3>
                                </td>
                                <td
                                    className={
                                    " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
                                    }
                                    onClick={() => handleRowClick(parking.id)}
                                >
                                    <h3> {parking.parking_spot?.parking_spot_location} <br></br> <span className="text-xs text-gray-500">{parking.parking_spot?.parking_spot_level}</span></h3>
                                </td>
                                <td
                                      onClick={() => handleRowClick(parking.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3>{parking?.start_date}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(parking.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3>{parking?.end_date}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(parking.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3 className="text-indigo-500">{parking.parking_notes}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(parking.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    {parking.booking_id != -1 ? 'Booking related': 'Non-Booking Related'}
                                </td>
                                <td
                                    onClick={() => handleRowClick(parking.id)}
                                    className={
                                    "w-28 text-red-400 px-2 py-4 text-sm font-semibold"
                                    }
                                >
                                    {status}
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    !parkingList.length && 
                    <LoadingGeneral count={10} cols={7} empty={loaded} authorization={authorization}/> 
                }
            </tbody>
        )
    }

    return (
            <div className=" slide-in-blurred-top  font-display py-4">
                <div className="flex flex-row w-full">
                {/* Left Side */}
                <div className="flex flex-col lg:flex-row align-center items-center mb-6">
                    {/* Search Bar */}
                    <div className="flex flex-row border rounded-sm w-full mb-3 lg:mb-0 py-1 h-8">
                    <MdSearch className="text-xl text-gray-400 mx-1" />
                    <input
                        id={"search"}
                        onChange={handleInputChange}
                        className="text-sm w-96 searchBox"
                        placeholder={searchByParkingLocation ? 'Search by Parking Location' : 'Search by Parking Resident'}
                        // value={this.state.search}
                        onKeyDown={handleKeyDown}
                    ></input>
                    </div>
                    <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" 
                        onClick={() => {
                            sortBy === SortBy.ID_DESC ? updateList() : setSortBy(SortBy.ID_DESC)
                        }}
                    >Search
                    </button>

                     <span className="py-1 mt-2 lg:px-4 font-medium margin text-gray-500 self-start ">
                    Filters:
                    </span>
                    <div className="flex flex-row flex-wrap self-start w-96 mt-1">
                    <button 
                        className={
                        (searchByParkingLocation ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                        " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                        }
                        onClick={() => setSearchByParkingLocation(!searchByParkingLocation)}
                    >
                        Search by Parking Location
                    </button>
                    </div> 
                </div>
                <PageSelector
                    curpage={currentPageNumber}
                    totalpages={totalPages}
                    increase={pageUp} //Page Up
                    decrease={pageDown} //Page Down
                    setPerPage={setPerPage}
                    perPageOptions={[10, 20, 30, 40, 50]}
                    />
                </div>

                {/* Invoice Table */}
                <table className="table-auto w-full mb-8">
                <thead>
                    <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
                        <th className="py-2 "><div className="inline-flex items-center">ID {idSortButton}</div></th>
                        <th className="py-2 ">Parking Resident</th>
                        <th className="py-2 ">Parking Location</th>
                        <th className="py-2 "><div className="inline-flex items-center">Start Date {startDateSortButton}</div></th>
                        <th className="py-2 "><div className="inline-flex items-center">End Date {endDateSortButton}</div></th>
                        <th className="py-2 ">Parking Notes</th>
                        <th className="py-2 ">Booking Related</th>
                        <th className="py-2 "></th>
                    </tr>
                </thead> 

                    { tableCreation() }

                </table>
        </div>
    ) 
}

export default ParkingList;
