import React, { useEffect, useState } from "react";
import { createTheme, PaletteColorOptions, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  fetchGetJSON,
  fetchRequestJSON,
  regGet,
  regRequest,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { useParams } from "react-router";
import { useNotification } from "../Notifications/NotificationProvider";
import { BsExclamationCircle } from "react-icons/bs";

declare module "@mui/material/styles" {
  interface CustomPalette {
    forestGreen: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    forestGreen: true;
  }
}
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    forestGreen: createColor("#03937F"),
  },
});

const ExtensionForm = (props: any) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [date, setDate] = useState("");
  const [count, setCount] = useState(1);
  const [booking, setBooking] = useState<any>();
  const [notes, setNotes] = useState("");
  const [expired, setExpired] = useState<any>();
  // const [unit, setUnit] = useState<any>();

  let id = count;

  const validate = () => {
    return date.length;
  };
  const dispatch = useNotification();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const sig = new URLSearchParams(window.location.search).get("signature");
    //Need to call our extension create and then route
    let newExtension = await regRequest(
      "POST",
      `${Constants.API_PATH_EXTENSION_SUBMITFORM}?signature=${sig}`,
      {
        extend_until: date,
        booking_id: bookingId,
        notes: notes,
      }
    );

    if (newExtension.status === "Success") {
      HandleError(
        "Success",
        `Extension Request has been submitted`,
        "Thank you for requesting an extension with us today"
      );
    } else {
      HandleError(
        "Error",
        `Error Creating Extension`,
        `${newExtension.status}`
      );
    }
  };

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  useEffect(() => {
    let sig = new URLSearchParams(window.location.search).get("signature");
    let bookingDetails = regGet(
      `${Constants.API_PATH_EXTENSION_CLIENT_DETAILS}/${bookingId}?signature=${sig}`
    ).then((response: any) => {
      if (response.status === 'Success') {
        setBooking(response.data.booking);
        setExpired(response.data.isTodayAfterNTV);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {!expired ? (
        <div className="md:grid md:h-screen md:place-items-center formBackground">
          <form className="extensionForm responsive-width shadow-lg">
            <h2 className="h2">Request Extension</h2>
            <aside className="spacerY responsive-width" />

            <section className="rowStart sm:flex-row flex-col sm:mb-0 mb-2 responsive-width">
              <TextField
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                id="outlined-read-only-input"
                disabled
                fullWidth
                label="First Name"
                value={
                  booking?.tenant?.first_name
                    ? booking?.tenant.first_name
                    : "N/A"
                }
              />
              <aside className="spacerX sm:my-0 mt-1 responsive-width" />
              <TextField
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                id="outlined-read-only-input"
                disabled
                fullWidth
                label="Last Name"
                value={
                  booking?.tenant?.last_name ? booking?.tenant.last_name : "N/A"
                }
              />
            </section>

            <aside className="spacerY responsive-width" />
            <TextField
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              id="outlined-read-only-input"
              disabled
              fullWidth
              label="Unit Name"
              value={
                booking?.unit?.suite_name ? booking?.unit.suite_name : "N/A"
              }
            />

            <aside className="spacerY" />
            <TextField
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              id="outlined-read-only-input"
              disabled
              fullWidth
              label="Checkout"
              value={booking?.check_out ? booking?.check_out : "N/A"}
            />

            <aside className="spacerY" />
            <TextField
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              id="date"
              required
              fullWidth
              label="Extension Date"
              value={date}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              color="forestGreen"
              onChange={(e) => setDate(e.target.value)}
            />

            <aside className="spacerY" />
            <TextField
              inputProps={{
                style: {
                  padding: 16,
                },
              }}
              id="input"
              fullWidth
              label="Notes"
              multiline={false}
              onChange={(e) => setNotes(e.target.value)}
              color="forestGreen"
            />

            <aside className="spacerY" />
            <section className="rowEnd responsive-width">
              <Button
                // type="submit"
                // variant="contained"
                color="forestGreen"
                disabled={!validate()}
                onClick={handleSubmit}
                sx={{
                  color:'green'
                }}
              >
                Submit
              </Button>
            </section>

            <aside className="spacerY" />
          </form>
        </div>
      ) : (
        <div className="flex items-center flex-col">
          {/* <div className="justify-center"> */}
          <div className="flex w-5/12">
            <img
              src="https://storage.googleapis.com/svstings/Furnished-rentals-in-toronto.png"
              alt="Sky View Suites Logo"
            />
          </div>
          <div className="flex justify-center">
            <div className="flex flex-col py-24 px-2">
              <div className="flex justify-center">
                <BsExclamationCircle className="text-red-500 text-8xl font-bold items-center" />
              </div>
              <h1 className="text-center mt-12 text-2xl">
                Unfortunately, you are no longer able to extend this booking.
              </h1>
              <h2 className="text-center mt-7 text-gray-800 text-lg">
                Please contact&nbsp;
                <a
                  href="mailto:admins@skyviewsuites.com"
                  className="text-green-500 underline"
                >
                  admin@skyviewsuites.com
                </a>
                &nbsp;for support.
              </h2>
            </div>
          </div>
          {/* </div> */}
        </div>
      )}
    </ThemeProvider>
  );
};

export default ExtensionForm;
