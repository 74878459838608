import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { FiSettings } from "react-icons/fi";
import { RiUser3Fill } from "react-icons/ri";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
// import { formatFullDate } from "../../utils/DateFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { unitType, tenantType, listview } from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";
import { release } from "os";

// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  QuickViewButton: any;
};

type State = {
  blackout: boolean;
  bookings: Booking[];
  search: string;
  loaded: boolean;
  vacant: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  authorization: boolean;

  sortBy: SortBy;
};

type Booking = {
  booking_id: number;
  tenant_name: string;
  tenant_email: string;
  check_in_date: string;
  check_out_date: string;
  check_out_year: string;
  unit: string;
  unit_id: number;
  confirmed_check_out: string;
  cancelled: boolean;
  confirmed: boolean;
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;
  release: boolean;
};

type BookingItem = {
  id: number;
  check_in: string;
  check_out: string;
  cancelled: boolean;
  confirmed: boolean;
  confirmed_check_out: string;
  unit_id: number;
  tenant_id: number;
  unit: string;
  tenant_name: string;
  tenant_email: string;
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;
  release: boolean;
};

enum SortBy {
  ID_DESC = "id DESC",
  ID_ASC = "id ASC",
  CHECKIN_DESC = "check_in DESC",
  CHECKIN_ASC = "check_in ASC",
  CHECKOUT_DESC = "check_out DESC",
  CHECKOUT_ASC = "check_out ASC",
}

class BookingList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      bookings: [],
      search: "",
      loaded: false,
      vacant: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      authorization: true,

      sortBy: SortBy.ID_DESC,
    };
  }

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState(
      {
        curpage: page,
        loaded: false,
      },
      async () => {
        await this.updateList();
      }
    );
  };

  pageDown = async () => {
    let pageNumber = this.state.curpage - 1;
    if (pageNumber == 0) {
      pageNumber += 1;
    }
    this.setState(
      {
        curpage: pageNumber,
        loaded: false,
      },
      async () => {
        await this.updateList();
      }
    );
  };

  // TODO: Refactor this function by removing the nested async calls.
  setPerPage = async (val: number) => {
    await this.setState({ perpage: val, loaded: false }, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState(
          {
            curpage: this.state.totalpages,
          },
          async () => {
            await this.updateList();
          }
        );
      }
    });
  };

  HandleBlackOut = () => {
    this.setState({ blackout: !this.state.blackout });
  };

  HandleRowClick = (id: number) => {
    // store.dispatch({ type: "bookingIdTransfer", id: id });
    // Might have to add a redux dispatch here like in corporate list
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: `${location.pathname}/bookingprofile`,
        search: `id=${id}`,
      });
  };

  componentDidMount = async () => {
    this.updateList();
    store.dispatch({
      type: "bookingListUpdate",
      listUpdate: this.updateList,
    });
  };

  // TODO: Refactor this function.
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // used for vacant filter
  // handleVacant = () =>{
  //   this.setState({ vacant: !this.state.vacant })
  //   this.updateList();
  // }

  // Populate the list entries from the database bookings relation
  updateList = async () => {
    let filter: any = {
      search: this.state.search,
      vacant: this.state.vacant,
    };
    const allBookings: listview<BookingItem[]> = await fetchGetJSON(
      encodeURI(
        `${Constants.API_PATH_LISTVIEW_BOOKINGS}?${
          this.state.search.length
            ? `search=${this.state.search.trim()}`
            : `search`
        }&page=${this.state.curpage}&perpage=${this.state.perpage}&vacant=${
          this.state.vacant
        }&sortBy=${this.state.sortBy}`
      )
    );
    if (allBookings.status !== "Success") {
      this.setState({
        loaded: true,
        authorization:
          allBookings.status == "Error Unauthorized" ? false : true,
      });

      return;
    }

    this.setState({ totalpages: allBookings.data.pages });

    let bookingList = []; // Make a copy of the state invoices list so I can reset the state and rerender the page
    for (let booking of allBookings.data.rows) {
      let bookingObj = {
        booking_id: -1,
        tenant_name: "",
        tenant_email: "",
        unit: "",
        unit_id: -1,
        check_in_date: "",
        check_out_date: "",
        check_out_year: "",
        confirmed_check_out: "",
        cancelled: false,
        confirmed: false,
        // exists: false
        keys_confirm: false,
        payment_confirm: false,
        cleaning_confirm: false,
        release: false,
      };
      // Bookings are soft deleted so check if they are supposed to show up or not
      bookingObj.booking_id = booking.id;
      bookingObj.check_in_date = booking.check_in.split("T")[0];
      bookingObj.check_out_date = booking.check_out.split("T")[0];
      bookingObj.tenant_email = booking.tenant_email;
      bookingObj.tenant_name = booking.tenant_name;
      bookingObj.unit = booking.unit;
      bookingObj.confirmed_check_out = booking.confirmed_check_out;
      bookingObj.cancelled = booking.cancelled;
      bookingObj.confirmed = booking.confirmed;
      bookingObj.unit_id = booking.unit_id;
      booking.confirmed_check_out
        ? (bookingObj.check_out_year = booking.confirmed_check_out.substr(0, 4))
        : (bookingObj.check_out_year = "N/A");
      bookingObj.keys_confirm = booking.keys_confirm;
      bookingObj.payment_confirm = booking.payment_confirm;
      bookingObj.cleaning_confirm = booking.cleaning_confirm;
      bookingObj.release = booking.release;

      bookingList.push(bookingObj);
    }
    this.setState({
      bookings: bookingList,
      loaded: true,
    });
  };

  cogClick = (id: number) => {
    store.dispatch({
      type: "bookingIdTransfer",
      id: id,
    });
    this.props.QuickViewButton();
  };

  filterButtonClick = (sortBy: SortBy) => {
    this.setState({ sortBy: sortBy }, async () => {
      this.updateList();
    });
  };

  render() {
    let blackoutStyles;
    let Warnings;
    // let blackoutToggle = <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"}/>
    if (this.state.blackout) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
    } else {
      blackoutStyles = "transition-all duration-200";
      Warnings = "";
    }

    let notSelectedStyle = "text-gray-500 hover:text-green-400";
    let selectedStyle = "text-green-500 hover:text-green-400";
    let filterIconSize = 32;
    let idSortButton = <></>;
    let checkinSortButton = <></>;
    let checkoutSortButton = <></>;
    idSortButton = (
      <MdArrowDropDown
        size={filterIconSize}
        className={notSelectedStyle}
        onClick={() => this.filterButtonClick(SortBy.ID_DESC)}
      />
    );
    checkinSortButton = (
      <MdArrowDropDown
        size={filterIconSize}
        className={notSelectedStyle}
        onClick={() => this.filterButtonClick(SortBy.CHECKIN_DESC)}
      />
    );
    checkoutSortButton = (
      <MdArrowDropDown
        size={filterIconSize}
        className={notSelectedStyle}
        onClick={() => this.filterButtonClick(SortBy.CHECKOUT_DESC)}
      />
    );

    switch (this.state.sortBy) {
      case SortBy.ID_DESC: {
        idSortButton = (
          <MdArrowDropDown
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.ID_ASC)}
          />
        );
        break;
      }
      case SortBy.ID_ASC: {
        idSortButton = (
          <MdArrowDropUp
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.ID_DESC)}
          />
        );
        break;
      }
      case SortBy.CHECKIN_DESC: {
        checkinSortButton = (
          <MdArrowDropDown
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.CHECKIN_ASC)}
          />
        );
        break;
      }
      case SortBy.CHECKIN_ASC: {
        checkinSortButton = (
          <MdArrowDropUp
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.CHECKIN_DESC)}
          />
        );
        break;
      }
      case SortBy.CHECKOUT_DESC: {
        checkoutSortButton = (
          <MdArrowDropDown
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.CHECKOUT_ASC)}
          />
        );
        break;
      }
      case SortBy.CHECKOUT_ASC: {
        checkoutSortButton = (
          <MdArrowDropUp
            size={filterIconSize}
            className={selectedStyle}
            onClick={() => this.filterButtonClick(SortBy.CHECKOUT_DESC)}
          />
        );
        break;
      }
    }

    let TableRows = <></>;
    let rows = [];
    for (let i = 0; i < this.state.bookings.length; i++) {
      let curBookingId = this.state.bookings[i].booking_id;
      // if (!(this.state.search == "" || this.state.bookings[i].tenant_name.toLowerCase().includes(this.state.search.toLowerCase()))) { continue; }

      let SpecialStyle = "";
      let SpecialStyle2 = "";
      let Special = <></>;
      if (this.state.bookings[i].confirmed) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-blue-400 mr-10";
        Special = (
          <span className="bg-blue-500 py-2 px-2 text-center rounded-full text-white">
            Confirmed
          </span>
        );
      }
      /* if (this.state.bookings[i].confirmed_check_out) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-green-400 mr-10";
        Special = <div className="bg-green-500 py-2 px-2 text-center rounded-full text-white overflow text-center">Checked Out</div>;
      } */
      if (this.state.bookings[i].cancelled) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-red-400 mr-10";
        Special = (
          <span className="bg-red-500 py-2 px-2 text-center rounded-full text-white">
            Cancelled
          </span>
        );
      }

      if (this.state.bookings[i].release) {
        Special = (
          <span className="bg-orange-500 py-2 px-2 text-center rounded-full text-white">
            Released
          </span>
        );
      }

      let keyStatus = <></>;
      keyStatus = (
        <span
          className={`font-bold mx-1 text-${
            this.state.bookings[i].keys_confirm ? "green" : "red"
          }-500`}
        >
          K
        </span>
      );

      let cleaningStatus = <></>;
      cleaningStatus = (
        <span
          className={`font-bold mx-1 text-${
            this.state.bookings[i].cleaning_confirm ? "green" : "red"
          }-500`}
        >
          C
        </span>
      );

      let paymentStatus = <></>;
      paymentStatus = (
        <span
          className={`font-bold mx-1 text-${
            this.state.bookings[i].payment_confirm ? "green" : "red"
          }-500`}
        >
          $
        </span>
      );

      TableRows = (
        <tr
          key={i.toString()}
          className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full"
        >
          {/* ID*/}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles}  w-full lg:w-20 font-thin  lg:text-left text-sm text-gray-700 px-1 mb-2 lg:mb-0  pt-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Booking ID: </span>
            {this.state.bookings[i].booking_id}
          </td>

          {/* Tenant */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className=" w-full lg:w-60 font-light text-sm text-gray-500 px-1 py-3 lg:py-4"
          >
            <div className="flex flex-row items-center">
              <div className="bg-gray-100 rounded-sm  w-10 h-10 shadow-sm flex flex-col justify-center items-center">
                <RiUser3Fill className="text-xl" />
              </div>
              <div className="px-4 flex flex-col ">
                <span className="text-base text-gray-600 font-semibold truncate lg:w-36">
                  {this.state.bookings[i].tenant_name}
                </span>
                <span className="text-xs text-gray-400 font-thin">
                  {this.state.bookings[i].tenant_email}
                </span>
              </div>
            </div>
          </td>

          {/* Unit */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} lg:w-40 px-1 py-2 lg:py-4`}
          >
            {/* <Tooltip content={<UnitBubble />} direction="" delay="">
              <div
                id="bookingConfirmed"
                className="  cursor-pointer h-6 mr-2 bg-gray-700 text-white font-medium text-xs lg:text-2xs rounded-full py-1 w-auto  flex items-center text-center px-5"
              >
                {" "}
                Signature 2{" "}
              </div>
            </Tooltip> */}
            {this.state.bookings[i].unit}
          </td>

          {/* Check In */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} border-b lg:border-none  lg:w-32 font-thin text-sm text-gray-700 px-1 mt-2 lg:mt-0 py-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Check In: </span>
            {this.state.bookings[i].check_in_date}
          </td>

          {/* Check Out */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} lg:border-none  lg:w-32 font-thin text-sm text-gray-700 lg:text-gray-400 px-1  lg:mt-0 py-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Check Out: </span>
            {this.state.bookings[i].check_out_date}
          </td>

          {/* Booking Warnings */}
          {/* I dont think bookings can be blacked out so this will be the checked out marker */}
          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} lg:border-none  lg:w-32 font-thin text-sm text-gray-700 lg:text-gray-400 px-1  lg:mt-0 py-3 lg:py-4`}
          >
            <span className="flex-row justify-center my-3 ml-4">
              <span className="text-sm font-bold h-6 lg:px-5 py-2 rounded-md shadow-sm">
                {keyStatus} {cleaningStatus} {paymentStatus}
              </span>
            </span>
          </td>

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className=" w-28 text-red-400 px-2 py-4 text-sm font-semibold"
          >
            {Warnings}
            {Special}
          </td>

          {/* Booking Warnings */}

          {/* <td className=" w-10 hidden lg:table-cell   px-1  "> */
          /* <div className="flex flex-row justify-end">
              <FiSettings
                // onClick={this.props.QuickViewButton}
                onClick={() => this.cogClick(curBookingId)}
                className={`${blackoutStyles} text-gray-700 text-2xl mr-2 hover:text-gray-500`}
              />
            </div> */
          /* </td> */}
        </tr>
      );
      rows.push(TableRows);
    }

    const handleKeyDown = (event: any) => {
      if (event.key == "Enter") {
        this.setState({ sortBy: SortBy.ID_DESC }, async () => {
          await this.updateList();
        });
      }
    };

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full lg:w-2/6 h-8 py-1">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                onChange={this.handleInputChange}
                className="text-sm searchBox w-full"
                placeholder="Search by booking id, unit name, or tenant"
                id="search"
                value={this.state.search}
                onKeyDown={handleKeyDown}
              ></input>
            </div>
            <button
              className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white"
              onClick={() => {
                this.setState({ sortBy: SortBy.ID_DESC }, async () => {
                  await this.updateList();
                });
              }}
            >
              Search
            </button>
            {/* unused filter component
            <div className="hidden lg:flex flex-row ">
              <span className="py-1 px-4 font-medium margin text-gray-500 ">Filters:</span>
              <button
                onClick={this.handleVacant}
                className={
                  (this.state.vacant ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 " +
                  "rounded-sm px-5 flex flex-row justify-center align-center"
                }
              >
                Vacant
              </button>
            </div> 
            */}
            <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 ">
                <div className="inline-flex items-center">
                  ID {idSortButton}
                </div>
              </th>
              <th className="py-2  ">Tenant</th>
              <th className="py-2  ">Unit</th>
              <th className="py-2  ">
                <div className="inline-flex items-center">
                  Check In {checkinSortButton}
                </div>
              </th>
              <th className="py-2  ">
                <div className="inline-flex items-center">
                  Check Out {checkoutSortButton}
                </div>
              </th>
              <th className="py-2  ">Light Status</th>
              {/* Checked out marker: Originally Book Warnings */}
              <th className="py-2 "></th>
              {/* Options */}
              {/* <th className="py-2 "></th> */}
            </tr>
          </thead>
          <tbody>
            {rows.length == 0 ? (
              <LoadingGeneral
                count={10}
                cols={8}
                empty={this.state.loaded}
                authorization={this.state.authorization}
              />
            ) : (
              rows
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(BookingList);
