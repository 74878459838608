import React, { Component, useState } from "react";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import ToggleSwitch from "../ToggleSwitch";
import { Link, withRouter, useHistory, useRouteMatch } from "react-router-dom";
import DatePicker from "../DatePicker";
import { formatFullDateString } from "../../utils/DateFormat";
import { fetchGetJSON, fetchRequestJSON, } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { type } from "os";
import { withHooksHOC } from "../../components/Notifications/NotificationProvider";
import { ZeroMonthlyFeeModal } from "./ZeroMonthlyFeeModal";

interface Props {
  dayCount: number;
  data: any;
  ErrorNote: any;
  openHighRisk: any;
}

interface SummaryInfoState {
  booking_nights: number;
  disabled: boolean;
}

class BookingSummary extends Component<Props, SummaryInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      booking_nights: this.props.dayCount,
      disabled: false,
    };
  }

  getPricingFrequency() {
    if (this.props.data.pricing_type == "monthly") {
      return "Month";
    } else if (this.props.data.pricing_type == "nightly") {
      return "Night";
    } else if (this.props.data.pricing_type == "thirty_day") {
      return "30 Day";
    } else {
      return "";
    }
  }

  getDayCount() {
    if (Number.isNaN(this.props.dayCount)) {
      return -1;
    } else {
      return this.props.dayCount;
    }
  }

  getDayCountStyle(dayCount: number) {
    if (dayCount < 0) {
      return "hidden";
    } else {
      return "";
    }
  }

  getNights(dayCount: number) {
    if (dayCount < 0) {
      return "";
    } else if (dayCount == 1) {
      return "Night";
    } else {
      return "Nights";
    }
  }

  render() {
    let dayCount = this.getDayCount();
    return (
      <div key={this.props.data} className="w-7/12 flex flex-row my-8">
        <div className="flex flex-col border border-solid rounded-sm py-3 z-30 px-4 w-7/12 shadow-lg ">
          <span
            key={this.props.dayCount}
            className="font-medium text-lg text-gray-700"
          >
            <span className={this.getDayCountStyle(dayCount)}>{this.getDayCount()} </span>
            {this.getNights(dayCount)} -
            <span className="text-gray-400 text-base font-normal">
              {" "}
              {this.props.data.booking_unit}{" "}
            </span>
          </span>

          <span className="text-xs text-gray-300 font-thin mb-2">Corporate - {" " + this.props.data.corporate_id}</span>

          <span className="text-xs text-gray-500 font-thin mb-1">
            {formatFullDateString(this.props.data.check_in, "short")} -{" "}
            {formatFullDateString(this.props.data.check_out, "short")}
          </span>
          <span className=" text-xs text-gray-800 font-regular">
            {formatAmountForDisplay(this.props.data.rate, "can", true) + " per " + this.getPricingFrequency()}
          </span>
        </div>

        <CompleteLink data={this.props.data} linkEnd={""} NotificationHandler={this.props.ErrorNote} corporate_id={this.props.data.corporate_id} corporate_deduction={this.props.data.corporate_deduction} openHighRisk={this.props.openHighRisk} />
      </div>
    );
  }
}

export default withHooksHOC(BookingSummary);

type postBody = {
  first_name: string,
  last_name: string,
  phone_number: string,
  email?: string
}

export function CompleteLink(this: any, props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.

  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;
  path = path.replace("/new/manual-booking/info", "");
  const [disabled, setDisabled] = useState(false);
  const isMonthlyRateZero = props.data.rate === 0;
  const [openZeroModal, setOpenZeroModal] = useState(false);


  // Only create new tenant if the info filled out is new so
  const retrieveTenantInformation = async () => {
    let tenantBody = store.getState().bookingReducer.bookingTenant;
    if (tenantBody.id !== -1) {
      return tenantBody;
    }

    let newTenantBody: postBody = {
      phone_number: tenantBody.phone_number.trim(),
      first_name: tenantBody.first_name.trim(),
      last_name: tenantBody.last_name.trim()
    }
    if (tenantBody.email && tenantBody.email.trim() !== "") {
      newTenantBody.email = tenantBody.email.trim();
    }
    console.log(newTenantBody)

    if (newTenantBody.first_name.trim() !== "" && newTenantBody.last_name.trim() !== "") {
      let newTenant = await fetchRequestJSON("POST", Constants.API_PATH_TENANT_CREATE, newTenantBody);
      console.log(newTenant.data)
      if (newTenant.data) {
        return newTenant.data.id;
      } else {
        return {};
      }
    }
    else {
      props.NotificationHandler(
        "Warning",
        `Error Retrieving Tenant`,
        "No First Name or Last Name for Tenant, please fill in tenant details"
      );
      return null;
    }

  };


  const dayDiff = (date_a: Date, date_b: Date) => {
    return Math.floor((date_b.getTime() - date_a.getTime()) / (1000 * 60 * 60 * 24));
  };

  // These 2 calculate flat deduction functions are not working yet but once done they should be able to allow people to enter
  // custom flat dollar amount deductions when making a booking. As of right now, when you select the corporate booking tick,
  // you can only enter a percentage to be deducted from the total. These functions will hopefully calculate the booking total
  // correctly (copied functionality from invoice calculation as of now) and allow you to deduct your flat amount if its under 
  // the total. However, if Matthew is ok with just a percentage corporate deduction then we can remove these.

  const calculateFlatDeductionPercentThirtyDay = () => {
    const flatFee = store.getState().bookingReducer.corporate_deduction;
    const basicBookingInfo = store.getState().bookingReducer.newBooking;
    const totalDays = dayDiff(basicBookingInfo.check_in, basicBookingInfo.check_out);
    let amount = 0;

    // Initial amount set
    if (totalDays >= 30) {
      amount += Number(basicBookingInfo.monthly_rate);
    } else {
      amount += Number(basicBookingInfo.monthly_rate) * (totalDays / 30);
    }

    amount += basicBookingInfo.pet_fee;
    amount += basicBookingInfo.parking_fee;
    amount += basicBookingInfo.cleaning_fee;

    // Add monthly rate per each month
    if (totalDays > 30) {
      for (let i = totalDays - 30; i >= 30; i -= 30) {
        amount += Number(basicBookingInfo.monthly_rate);
      }
    }

    // Add remaining amount for last few days
    if (totalDays % 30 !== 0) {
      let endDate = new Date(basicBookingInfo.check_in);
      endDate.setUTCDate(endDate.getUTCDate() + 29);
      let newBeginDate = new Date(endDate);
      newBeginDate.setUTCDate(newBeginDate.getUTCDate() + 1);
      let lastEndDate = new Date(basicBookingInfo.check_out);
      lastEndDate.setUTCDate(lastEndDate.getUTCDate() - 1);

      let diff = Math.abs(newBeginDate.getTime() - lastEndDate.getTime());
      diff = Math.round(diff / (1000 * 60 * 60 * 24));

      amount += basicBookingInfo.monthly_rate * ((diff + 1) / 30);
    }

    if (flatFee >= amount) {
      return 0;
    } else {
      return flatFee / amount;
    }
  };

  const calculateFlatDeductionPercentMonthly = () => {
    const flatFee = store.getState().bookingReducer.corporate_deduction;
    const basicBookingInfo = store.getState().bookingReducer.newBooking;
    const lastDayOfCheckInMonth = new Date(
      new Date(basicBookingInfo.check_in).getUTCFullYear(),
      new Date(basicBookingInfo.check_in).getUTCMonth() + 1,
      0
    );
    let amount = 0;
    const checkInArr = new Date(basicBookingInfo.check_in)
      .toUTCString()
      .split(" ");
    const checkOutArr = new Date(basicBookingInfo.check_out)
      .toUTCString()
      .split(" ");
    const end =
      checkInArr[2] == checkOutArr[2] && checkInArr[3] == checkOutArr[3]
        ? new Date(basicBookingInfo.check_out).toISOString().substring(0, 10)
        : lastDayOfCheckInMonth.toISOString().substring(0, 10);

    const diff = Math.abs(new Date(basicBookingInfo.check_in).getTime() - new Date(end).getTime());
    const diffInDays = Math.round((diff / 1000) * 60 * 60 * 24);

    if (basicBookingInfo.check_out > lastDayOfCheckInMonth) {
      if (new Date(basicBookingInfo.check_in).getUTCDate() === 1) {
        amount += basicBookingInfo.monthly_rate;
      } else {
        amount += basicBookingInfo.monthly_rate * ((diffInDays + 1) / 30);
      }
    } else {
      amount += basicBookingInfo.monthly_rate * ((diffInDays + 1) / 30);
    }

    amount += basicBookingInfo.pet_fee;
    amount += basicBookingInfo.parking_fee;
    amount += basicBookingInfo.cleaning_fee;

    if (moreInvoices(basicBookingInfo.check_in, basicBookingInfo.check_out)) {
      const yearDiff =
        new Date(basicBookingInfo.check_out).getUTCFullYear() -
        new Date(basicBookingInfo.check_in).getUTCFullYear();
      let monthDiff =
        new Date(basicBookingInfo.check_out).getUTCMonth() -
        new Date(basicBookingInfo.check_in).getUTCMonth();
      if (yearDiff > 0) {
        monthDiff += yearDiff * 12;
      }
      // exclude last month
      monthDiff -= 1;
      for (let i = 1; i <= monthDiff; i++) {
        amount += basicBookingInfo.monthly_rate;
      }
    }

    let lastDiff = Math.abs(
      new Date(
        new Date(basicBookingInfo.check_out).getUTCFullYear(),
        new Date(basicBookingInfo.check_out).getUTCMonth(),
        1
      ).getTime() - new Date(basicBookingInfo.check_out).getTime()
    );
    lastDiff = Math.round(lastDiff / (1000 * 60 * 60 * 24));

    // Last month price calc
    if (
      new Date(
        new Date(basicBookingInfo.check_out).getUTCFullYear(),
        new Date(basicBookingInfo.check_out).getUTCMonth() + 1,
        0
      ).getUTCDate() == new Date(basicBookingInfo.check_out).getUTCDate()
    ) {
      amount += basicBookingInfo.monthly_rate;
    } else {
      amount += basicBookingInfo.monthly_rate * ((lastDiff + 1) / 30);
    }

    if (flatFee >= amount) {
      return 0;
    } else {
      return flatFee / amount;
    }
  };

  const isSameYearMonthCheckout = (check_in: Date, check_out: Date) => {
    return check_in.getUTCFullYear() === check_out.getUTCFullYear() &&
      check_in.getUTCMonth() === check_out.getUTCMonth();
  }

  // TODO: Refactor this function by simplifying the logic for determining
  // TODO: whether we require more invoices.
  const moreInvoices = (check_in: Date, check_out: Date) => {
    check_in = new Date(check_in);
    check_out = new Date(check_out);
    if (isSameYearMonthCheckout(check_in, check_out)) {
      return false;
    } else {
      check_out.setUTCDate(check_out.getUTCDate() - 1);
      return !isSameYearMonthCheckout(check_in, check_out);
    }
  };

  const getCorporateDeduction = () => {
    if (!props.corporate_deduction || !props.data.corporate) {
      return 0;
    } else if (props.corporate_deduction < 0) {
      return 0;
    } else if (props.corporate_deduction > 100) {
      return 1;
    } else {  // 0 <= props.corporate_deduction && props.corporate_deduction <= 1
      return props.corporate_deduction / 100; //want to make it percentage
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(props);

    if (openZeroModal) {
      closeModal();
    }

    // Booking a corporate contact vs. a non-corporate contact.
    let corporateId: number = props.data.corporate ? props.corporate_id : null;
    let corporateAdminId;
    let corporateCleaningId;
    let corporateFinanceId;
    let corporateDeduction = getCorporateDeduction();
    if (props.data.corporate) {  // Corporate booking
      let allCorporateContacts = await fetchGetJSON(Constants.API_PATH_CORP_CONTACT_GET_ALL);
      // TODO: Consider matching admin, cleaning, and finance contacts on corporate id as well?
      let corporateAdminContact = props.data.admin_id ? props.data.admin_id : props.data.admin_ids[0];
      let corporateCleaningContact = props.data.cleaning_id ? props.data.cleaning_id : props.data.cleaning_ids[0];
      let corporateFinanceContact = props.data.finance_id ? props.data.finance_id : props.data.finance_ids[0];

      corporateAdminId = corporateAdminContact ? corporateAdminContact : null;
      corporateCleaningId = corporateCleaningContact ? corporateCleaningContact : null;
      corporateFinanceId = corporateFinanceContact ? corporateFinanceContact : null;
    } else {  // Non-corporate booking
      corporateAdminId = null;
      corporateCleaningId = null;
      corporateFinanceId = null;
    }

    // Booking an exising tenant vs. a new tenant.
    let tenantInfo = store.getState().bookingReducer.bookingTenant;

    // Creating a completely new booking vs. a saved unfinished booking.
    let bookingCreate;
    let basicBookingInfo = store.getState().bookingReducer.newBooking;
    if (!basicBookingInfo) {
      if (tenantInfo.id) {
        bookingCreate = {
          apply_hst: props.data.auto_hst,
          check_in: props.data.check_in,
          check_out: props.data.check_out,
          unit_id: props.data.unit_id,
          monthly_rate: Number(props.data.rate),
          cleaning_frequency: props.data.cleaning_frequency,
          cleaning_fee: props.data.cleaning_fee,
          created_by: `${tenantInfo.first_name} ${tenantInfo.last_name}`,
          pets: false,
          booking_type: props.data.pricing_type,  // TODO: Rename props.data.pricing_type to props.data.booking_type?
          corporate_id: corporateId,
          corporate_admin_id: corporateAdminId,
          corporate_cleaning_id: corporateCleaningId,
          corporate_finance_id: corporateFinanceId,
          booking_notes: props.data.booking_notes,
          parking_requested: false,
          deposit_expiry: props.data.deposit_expiry,
          deposit_amount: Number(props.data.deposit),
          pet_fee: 0,
          parking_fee: 0,
          corporate_deduction: corporateDeduction,
          hard_checkout: props.data.hard_checkout,
          tenant_id: tenantInfo.id,
          fobs: 0,
          keys: 0,
          parking_fobs: 0,
          mail_keys: 0,
          parking: null,
          ru_reservation_id: props.data?.rentalsUnitedReservationID?.length ? props.data.rentalsUnitedReservationID : null,
        };
      } else {
        bookingCreate = {
          apply_hst: props.data.auto_hst,
          check_in: props.data.check_in,
          check_out: props.data.check_out,
          unit_id: props.data.unit_id,
          monthly_rate: Number(props.data.rate),
          cleaning_frequency: props.data.cleaning_frequency,
          cleaning_fee: props.data.cleaning_fee,
          created_by: `${tenantInfo.first_name} ${tenantInfo.last_name}`,
          pets: false,
          booking_type: props.data.pricing_type,  // TODO: Rename props.data.pricing_type to props.data.booking_type?
          corporate_id: corporateId,
          corporate_admin_id: corporateAdminId,
          corporate_cleaning_id: corporateCleaningId,
          corporate_finance_id: corporateFinanceId,
          booking_notes: props.data.booking_notes,
          parking_requested: false,
          deposit_expiry: props.data.deposit_expiry,
          deposit_amount: Number(props.data.deposit),
          pet_fee: 0,
          parking_fee: 0,
          corporate_deduction: corporateDeduction,
          hard_checkout: props.data.hard_checkout,
          first_name: tenantInfo.first_name ? tenantInfo.first_name : "N/A",
          last_name: tenantInfo.last_name ? tenantInfo.last_name : "N/A",
          email: tenantInfo.email ? tenantInfo.email : null,
          phone_number: tenantInfo.phone_number ? tenantInfo.phone_number : null,
          fobs: 0,
          keys: 0,
          parking_fobs: 0,
          mail_keys: 0,
          parking: null,
          ru_reservation_id: props.data?.rentalsUnitedReservationID?.length ? props.data.rentalsUnitedReservationID : null,
        };
      }
    } else {  // Existing booking
      // if (!store.getState().bookingReducer.deduction_percent) {
      //   corporateDeduction =
      //     basicBookingInfo.booking_type == "monthly"
      //       ? calculateFlatDeductionPercentMonthly()
      //       : calculateFlatDeductionPercentThirtyDay();
      // }
      if (tenantInfo.id) {
        bookingCreate = {
          apply_hst: props.data.auto_hst,
          check_in: basicBookingInfo.check_in,
          check_out: basicBookingInfo.check_out,
          unit_id: basicBookingInfo.unit_id,
          monthly_rate: Number(basicBookingInfo.monthly_rate),
          cleaning_frequency: basicBookingInfo.cleaning_frequency,
          created_by: basicBookingInfo.created_by,
          pets: basicBookingInfo.pet_fee !== "0" || basicBookingInfo.pet_fee !== "" ? true : false,
          booking_type: basicBookingInfo.booking_type,
          tenant_id: tenantInfo.id,
          corporate_id: corporateId,
          corporate_admin_id: corporateAdminId,
          corporate_cleaning_id: corporateCleaningId,
          corporate_finance_id: corporateFinanceId,
          booking_notes: basicBookingInfo.booking_notes,
          parking_requested: basicBookingInfo.parking_requested,
          deposit_expiry: basicBookingInfo.deposit_expiry,
          deposit_amount: basicBookingInfo.deposit,
          pet_fee: basicBookingInfo.pet_fee,
          cleaning_fee: basicBookingInfo.cleaning_fee,
          parking_fee: basicBookingInfo.parking_fee,
          corporate_deduction: corporateDeduction,
          hard_checkout: basicBookingInfo.hard_checkout,
          fobs: 0,
          keys: 0,
          parking_fobs: 0,
          mail_keys: 0,
          parking: null,
          ru_reservation_id: props.data?.rentalsUnitedReservationID?.length ? props.data.rentalsUnitedReservationID : null,
        };
      } else {
        bookingCreate = {
          apply_hst: props.data.auto_hst,
          check_in: basicBookingInfo.check_in,
          check_out: basicBookingInfo.check_out,
          unit_id: basicBookingInfo.unit_id,
          monthly_rate: Number(basicBookingInfo.monthly_rate),
          cleaning_frequency: basicBookingInfo.cleaning_frequency,
          created_by: basicBookingInfo.created_by,
          pets: basicBookingInfo.pet_fee !== "0" || basicBookingInfo.pet_fee !== "" ? true : false,
          booking_type: basicBookingInfo.booking_type,
          corporate_id: corporateId,
          corporate_admin_id: corporateAdminId,
          corporate_cleaning_id: corporateCleaningId,
          corporate_finance_id: corporateFinanceId,
          booking_notes: basicBookingInfo.booking_notes,
          parking_requested: basicBookingInfo.parking_requested,
          deposit_expiry: basicBookingInfo.deposit_expiry,
          deposit_amount: basicBookingInfo.deposit,
          pet_fee: basicBookingInfo.pet_fee,
          cleaning_fee: basicBookingInfo.cleaning_fee,
          parking_fee: basicBookingInfo.parking_fee,
          corporate_deduction: corporateDeduction,
          hard_checkout: basicBookingInfo.hard_checkout,
          fobs: 0,
          keys: 0,
          parking_fobs: 0,
          mail_keys: 0,
          first_name: tenantInfo.first_name ? tenantInfo.first_name : "N/A",
          last_name: tenantInfo.last_name ? tenantInfo.last_name : "N/A",
          email: tenantInfo.email ? tenantInfo.email : null,
          phone_number: tenantInfo.phone_number ? tenantInfo.phone_number : null,
          parking: null,
          ru_reservation_id: props.data?.rentalsUnitedReservationID?.length ? props.data.rentalsUnitedReservationID : null,
        };
      }
    }
    //Going to actually have to cut all the if conditions down later when we optimize the code lmao but this is a working solution:
    if (bookingCreate.check_in instanceof Date && bookingCreate.check_out instanceof Date) {
      if (bookingCreate.check_in.getTime() === bookingCreate.check_out.getTime()) {
        props.NotificationHandler(
          "Warning",
          `Error Creating Booking`,
          "Please enter valid date field"
        );
      }
      return
    }

    if (bookingCreate.check_in instanceof Date && typeof bookingCreate.check_out === 'string') {
      props.NotificationHandler(
        "Warning",
        `Error Creating Booking`,
        "Please enter valid date field for check in"
      );
      return
    }
    else if (bookingCreate.check_out instanceof Date && typeof bookingCreate.check_in === 'string') {
      props.NotificationHandler(
        "Warning",
        `Error Creating Booking`,
        "Please enter valid date field for check out"
      );
      return
    }
    else if (typeof bookingCreate.check_in === 'string' && typeof bookingCreate.check_out === 'string') {
      //convert both values to date field and check if check_in is greater than check_out
      var checkInDate = new Date(bookingCreate.check_in);
      var checkOutDate = new Date(bookingCreate.check_out);

      if (checkInDate > checkOutDate) {
        props.NotificationHandler(
          "Warning",
          `Error Creating Booking`,
          "Please have check out date be greater than check in date"
        );
        return
      }
    }

    // Create new booking with the filled out info, corp info, and tenant
    if (!bookingCreate || bookingCreate.monthly_rate < 0) {
      props.NotificationHandler(
        "Warning",
        `Error Creating Booking`,
        "Please enter in all the following fields: check in date, check out date, valid unit name, monthly rate (cannot be less than 0), valid tenant information, valid corporate information"
      );
    } else {
      if (bookingCreate.pet_fee == 0 || bookingCreate.pet_fee == "") {
        bookingCreate.pets = false;
      }
      else {
        bookingCreate.pets = true;
      }

      if (!bookingCreate.corporate_id && !tenantInfo.email) {
        props.NotificationHandler(
          "Warning",
          `Error Creating Booking`,
          "No corporate id, please make sure there is an associated email for this tenant."
        );
        return;
      }

      console.log(bookingCreate);
      //Should honestly be set like this by default
      bookingCreate.booking_type = "thirty_day";

      //Need to do two things for parking;
      //1. We need to check if there is a parking overlap with our selected parking spot id
      //2. We need to check if there is 
      if(props.data.parkingRequired) {
        const parkingStatus = await fetchGetJSON(`${Constants.API_PATH_PARKING_OVERLAP_CHECK}?start_date=${bookingCreate.check_in}&end_date=${bookingCreate.check_out}&parking_spot_id=${+props.data.parking_spot_id}`, 
          // start_date: bookingCreate.check_in,
          // end_date: bookingCreate.check_out,
          // parking_spot_id: +props.data.parking_spot_id
        )

        //If parking status is not success
        if(parkingStatus.status != "Success") {
          props.NotificationHandler(
            "Warning",
            "Parking Conflict Detected",
            parkingStatus.data,
          )

          return
        } else {
          bookingCreate["parking"]= {
            parking_notes: props.data.parking_notes,
            parking_spot_id: +props.data.parking_spot_id
          }

          bookingCreate.parking_requested = true
        }
      }

      if (bookingCreate.tenant_id || (bookingCreate.first_name && bookingCreate.last_name)) {
        props.NotificationHandler(
          "Please wait while we process the information",
          `Creating Booking`,
          "Please wait while we process the information",
        );
        setDisabled(true);

        const createBookingStatus = await fetchRequestJSON("POST", Constants.API_PATH_BOOKING_CREATE, bookingCreate);
        console.log(createBookingStatus);
        
        if (createBookingStatus.status !== "Success") {
          props.NotificationHandler(
            "Error",
            "Error Creating Booking",
            `${createBookingStatus.status}`
          );
          setDisabled(false);
          return;

        } else {
          props.openHighRisk();
          props.NotificationHandler(
            "Success",
            `Successfully Created Booking`,
            "Find its associated invoice in the invoice tab"
          );
          history.push(`${path}`);
        }
      } else {
        props.NotificationHandler(
          "Warning",
          `Error Creating Booking`,
          "No tenant info, please go back to booking components and re-enter your tenant information",
        )
        setDisabled(false);
        return;
      }
    }
  };

  const openModal = () => {
    setOpenZeroModal(true);
    setDisabled(true);
  };

  const closeModal = () => {
    setOpenZeroModal(false);
    setDisabled(false);
  }

  return (
    <div>
      {isMonthlyRateZero && 
      <ZeroMonthlyFeeModal BookingData={props.data} open={openZeroModal} closeModal={closeModal} submit={handleSubmit}/>}
      <button
        onClick={isMonthlyRateZero ? openModal : handleSubmit}
        disabled={disabled}
        className="font-SF-Pro shadow-lg transition text-md duration-100 w-44 h-10 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center ml-3"
      >
        {!isMonthlyRateZero && disabled ? 'Creating Booking...' : 'Complete'}
      </button>

      {/* can be deprecated */}
      {/* <button
        onClick={handleSubmit}
        disabled={disabled}
        className="font-SF-Pro shadow-lg transition text-md duration-100 w-44 h-10 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center ml-3"
      >
        {disabled ? 'Creating Booking...' : 'Complete'}
      </button> */}
    </div>
  );
}