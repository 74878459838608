import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RiUser3Fill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";
import Tooltip from "../../Tooltip";
import UnitBubble from "../../UnitBubble";
import ToolTipAlt from "../../ToolTipAlt";
import { MdSearch, MdCheck } from "react-icons/md";
import { FcCancel } from "react-icons/fc"
import { fetchGetJSON, fetchRequestJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { addDays } from "../../../utils/DateFormat";
import { withHooksHOC } from "../../Notifications/NotificationProvider";

type Props = {
  history: any;
  handleCheckoutModal: any;
  getBookingData: any;
  ErrorNote: any;
};

type CheckOutTableState = {
  Bookings: any;
  search: string;
};

class CheckOutTable extends Component<
  Props & RouteComponentProps,
  CheckOutTableState
> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      Bookings: [],
      search: ""
    };
  }

  async componentDidMount() {
    let NotificationHandler = this.props.ErrorNote;
    let allBookings = await fetchGetJSON(`${Constants.API_PATH_GET_BOOKING_CHECK_OUT}?search=${this.state.search}`);
    if (allBookings.status !== "Success") {
      NotificationHandler("Error", "Error getting all bookings", "");
      this.setState({
        Bookings: [],
      });
    } else {
      this.setState({
        Bookings: allBookings.bookingData[0],
      });
    }
  }

  HandleRowClick = (bookingID: string) => {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: "/user/bookings/bookingprofile",
        search: `id=${bookingID}`,
      });
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  updateList = async () => {
    const body = {
      search: this.state.search.trim().replaceAll(' ', '%')
    };

    const allBookings = await fetchGetJSON(`${Constants.API_PATH_GET_BOOKING_CHECK_OUT}?search=${body.search}`,);
    if (allBookings.status !== "Success") {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler("Error", "Error getting all bookings", "");
      this.setState({
        Bookings: [],
      });
    } else {
      this.setState({
        Bookings: allBookings.bookingData[0],
      });
    }
  }

  getCheckInDateForBooking(targetBooking:any) {
    // Javascript moves the date back by one when creating the date from a string (I think it has to do with timezones)
    // Moving date forward to obtain the correct date.
    let bookingCheckOutDate = new Date(targetBooking.check_out);
    bookingCheckOutDate = addDays(bookingCheckOutDate, 1);
    const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(bookingCheckOutDate);
    const month = new Intl.DateTimeFormat("en", { month: "long" }).format(bookingCheckOutDate);
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(bookingCheckOutDate);
    let hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(bookingCheckOutDate);
    if (hour === "12 AM") {
      hour = "No Time Specified";
    }
    return { year, month, day };
  }

  handleCheckOutForm (booking:any) {
    this.props.handleCheckoutModal("toggleCheckOut");
    this.props.getBookingData(booking);
  }

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    let rows = [];
    for (let booking of this.state.Bookings) {
      if (booking.cancelled) {
        continue;
      }

      const { year, month, day } = this.getCheckInDateForBooking(booking);

      let row = (
        <tr className=" border-b grid grid-cols-1  lg:table-row cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full  ">
          {/* TODO: Consider removing the w-full attribute to make widths of columns resizable. */}
          <td
            onClick={() => this.HandleRowClick(booking.id.toString())}
            className="font-light text-sm text-gray-500 border-r-2 border-gray-400"
          >
            {/* TODO: Consider removing the text-gray-500 style sheet since the parent selector already has it */}
            {/* TODO: Consider removing the border-gray-400 style sheet since the parent selector already has it? */}
            {/* TODO: Consider changing border-r-2 to border-r-1 to make border width thinner. */}
            <div className="flex flex-row pr-4">
              {/* TODO: Consider removing the flex-row prperty since the parent selector already has it */}
              <ToolTipAlt message={(booking.corporate_id) ? "Corporate Client" : "Retail Booking"} position="bottom">
                <div className="bg-gray-200 rounded-sm w-12 h-12 shadow-sm flex flex-col justify-center items-center">
                  {(booking.corporate_id) ? <FaUserTie className="text-xl" /> : <RiUser3Fill className="text-xl" />}
                </div>
              </ToolTipAlt>

              <div className="px-4 flex flex-col ">
                <span className="text-base text-gray-600 font-semibold truncate h-7 py-1">
                  {`${booking?.tenant?.first_name} ${booking?.tenant?.last_name}`}
                </span>
                <span className="text-xs text-gray-400 font-thin h-5">
                  {booking?.tenant?.email}
                </span>
              </div>

            </div>
          </td>

          <td
            onClick={() => this.HandleRowClick(booking.id.toString())}
            className={" px-3 flex-col font-sofia border-r-2 border-gray-400 h-12 w-1/6"}
          >
            <Tooltip
              content={<UnitBubble suite_number={booking?.unit?.suite_number} blackout={false} heat={true}/>}
              direction=""
              delay=""
              checkList={true}
            >
              <div
                id="bookingConfirmed"
                className="  cursor-pointer text-white font-medium text-xs py-1 flex items-center px-5 my-3"
              >
                {/* TODO: Consider removing the cursor-pointer property since the parent selector already has it */}
                {/* TODO: Consider removing the w-auto property since the parent selector already has it */}
                {booking?.unit?.suite_name}{" "}
              </div>
            </Tooltip>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-col font-sofia border-r-2 border-gray-400 my-3 ml-3 h-12">
              <span className="text-sm font-bold text-gray-600 h-6">{`${month} ${day}`}</span>
              <span className="text-sm text-gray-400 h-6">{year}</span>
            </div>
          </td>

          {/* TODO: Replace this with confirmed time. */}
          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-col font-sofia border-r-2 border-gray-400 my-3 ml-3 h-12">
              <span className="text-sm font-bold text-gray-600">{`${month} ${day}`}</span>
              <span className="text-sm text-gray-400">{year}</span>
            </div>
          </td>

          <td onClick={() => this.HandleRowClick(booking.id.toString())}>
            <div className="flex flex-col justify-center font-sofia border-r-2 border-gray-400 py-3 ml-3 h-12">
              <span className="text-base text-gray-700 h-6">{(booking.check_out_notes) ? booking.check_out_notes : "No Notes"}</span>
            </div>
          </td>

          <td onClick={() => this.handleCheckOutForm(booking)}>
              <button
                className="transition duration-200 hover:text-green-400 px-8 lg:px-10 py-2 rounded-md text-green-500 font-bold shadow-sm mr-3"
              >
                Check Out Form
              </button>
          </td>
          <td>
             {booking.confirmed_check_out ? <span className="text-green-500 text-2xl"><MdCheck/></span> :  <span className="text-red-500 text-2xl"><FcCancel/></span>}
          </td>
        </tr>
      );
      rows.push(row);
    }

    return (
      <div className=" font-display ">
        <div className="flex flex-row w-full mt-6 mb-5">
          {/* Left Side */}
          <div className="flex flex-col lg:flex-row w-full align-center items-start lg:items-center mb-2">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full lg:w-1/3 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                id={"search"}
                onChange={this.handleInputChange}
                className="text-sm searchBox w-full"
                placeholder="Search booking ID, tenant name, or tenant email"
                value={this.state.search}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" 
              onClick={() => { this.updateList() }}
            >
              Search
            </button>
          </div>
        </div>

        <table className="table-auto w-full mb-8 ">
          <thead>
            <tr className="hidden lg:table-row text-left  text-gray-500 border-b border-gray-500  ">
              {/* TODO: Consider centering the text with the text-left property. */}
              <th className="py-4 w-12 font-thin ">Full Name</th>
              <th className="py-3 w-48  font-thin ">Unit</th>
              <th className="py-3 w-32 font-thin ">Check Out</th>
              <th className="py-3 w-auto font-thin">Confirmed Time</th>
              <th className="py-3 font-thin w-48">Check Out Notes</th>
              <th className="py-3 font-thin w-auto">Check Out Form</th>
              <th className="py-3 font-thin w-auto">Check Out Status</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(withHooksHOC(CheckOutTable));
