import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import store from "../../redux/store";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  corporateData: any;
  toggle: any;
  handleEditBarToggle: any;
  handleFileChange: any;
  handleFileRemove: any;
  handleInputChange: any;
  handleToggleChange: any;
  ErrorNote: any;
};

type EditUnitState = {
  id: number;
  adminId: number;
  financeId: number;
  cleaningId: number;
  corporation: string;
  address: string;
  admin_contact: string;
  admin_contact_email: string;
  admin_contact_phone: string;
  finance_contact: string;
  finance_contact_email: string;
  finance_contact_phone: string;
  cleaning_contact: string;
  cleaning_contact_email: string;
  cleaning_contact_phone: string;
  client_manager: string;
  payment_type: string;
  flagged: boolean;
  fin_com_pol: string;
  clean_com_pol: string;
  admin_com_pol: string;
  var_com_pol: boolean;
  invoice_method: string;
  ntv: number;
  payment_terms: string;
  first_last: string;

  charge_deposit: boolean;
  charge_cleaning: boolean;
  charge_credit_fee: boolean;

  corporate_notes: string;
  isNet30: boolean;

  expiry: number;
};

class EditCorporate extends Component<Props, EditUnitState> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: -1,
      adminId: -1,
      financeId: -1,
      cleaningId: -1,
      corporation: "",
      address: "",

      admin_contact: "",
      cleaning_contact: "",
      finance_contact: "",

      admin_contact_email: "",
      cleaning_contact_email: "",
      finance_contact_email: "",

      admin_contact_phone: "",
      cleaning_contact_phone: "",
      finance_contact_phone: "",

      client_manager: this.props.corporateData.client_manager,
      payment_type: this.props.corporateData.payment_terms,
      flagged: false,
      fin_com_pol: "",
      clean_com_pol: "",
      admin_com_pol: "",
      var_com_pol: false,
      invoice_method: "",
      ntv: -1,
      payment_terms: "",
      first_last: "",

      charge_deposit: true,
      charge_cleaning: true,
      charge_credit_fee: true,
      corporate_notes: "",
      isNet30: this.props.corporateData.payment_terms === 30 ? true : false,
      
      expiry: -1,
    };
  }

  // Fill out edit form with current information
  componentDidMount = async () => {
    let contactId = Number(
      new URLSearchParams(window.location.search).get("contact_id")
    );
    
    let corpId = Number(
      new URLSearchParams(window.location.search).get("corp_id")
    );

    let allContacts: any;
    let contactData: any;
    let allCorps: any;
    let corpData: any;
    // console.log(contactId, corpId)
    if (contactId) {
      allContacts = await fetchGetJSON(
        Constants.API_PATH_CORP_CONTACT_GET_ALL
      );
      console.log(allContacts)
      contactData = allContacts.data.find(
        (contactItem: any) => contactItem.id === contactId
      );
      allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
      corpData = allCorps.data.find(
        (corpItem: any) => corpItem.id === contactData.corporate_id
      );
    } else if (corpId) {
      // Add the search by corp data here:
      allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
      corpData = allCorps.data.find(
        (corpItem: any) => corpItem.id === corpId
      );
      allContacts = await fetchGetJSON(
        Constants.API_PATH_CORP_CONTACT_GET_ALL
      );
      contactData = allContacts.data.find(
        (contactItem: any) => contactItem.corporate_id === corpId
      );
    } else {
      alert("Missing ID something went wrong");
      return;
    }

    /*
    console.log(allCorps);
    console.log(corpData);
    console.log(allContacts);
    console.log(contactData);
    */
    


    let admin = allContacts.data.find(
      (contactItem: any) =>
        contactItem.corporate_id === corpData.id &&
        contactItem.admin &&
        contactItem.primary
    );
    let finance = allContacts.data.find(
      (contactItem: any) =>
        contactItem.corporate_id === corpData.id &&
        contactItem.finance &&
        contactItem.primary
    );
    let cleaning = allContacts.data.find(
      (contactItem: any) =>
        contactItem.corporate_id === corpData.id &&
        contactItem.cleaning &&
        contactItem.primary
    );

    const blank = {
      id: -1,
      first_name: "Does not",
      last_name: "exist",
      email: "--",
      phone_number: "--"
    }

    if (!admin) { admin = blank; }
    if (!cleaning) { cleaning = blank; }
    if (!finance) { finance = blank; }
    this.setState({
      id: corpData.id,
      adminId: admin.id,
      financeId: finance.id,
      cleaningId: cleaning.id,
      corporation: corpData.corporate_name,
      address: corpData.address,
      client_manager: corpData.client_manager,
      payment_type: corpData.method_of_payment,
      invoice_method: corpData.invoicing_policy,
      ntv: corpData.notice_to_vacate,
      payment_terms: corpData.payment_terms,
      first_last: corpData.first_last_policy,
      charge_cleaning: corpData.cleaning_fee,
      charge_credit_fee: corpData.credit_fee,
      charge_deposit: corpData.deposit_policy,
      corporate_notes: corpData.notes,

      admin_contact: `${admin.first_name} ${admin.last_name}`,
      cleaning_contact: `${cleaning.first_name} ${cleaning.last_name}`,
      finance_contact: `${finance.first_name} ${finance.last_name}`,

      admin_contact_email: admin.email,
      cleaning_contact_email: cleaning.email,
      finance_contact_email: finance.email,

      admin_contact_phone: admin.phone_number,
      cleaning_contact_phone: cleaning.phone_number,
      finance_contact_phone: finance.phone_number,

      fin_com_pol: corpData.finance_communication_policy,
      clean_com_pol: corpData.cleaning_communication_policy,
      admin_com_pol: corpData.admin_communication_policy,
      var_com_pol: corpData.variable_communication_policy,
      isNet30: corpData.payment_terms == 30 ? true : false,

      expiry: corpData.expiry
    });
  };

  handleInputChange = (event: any) => {
    console.log(event);
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  determinePaymentTerms = () => {
    switch (this.state.payment_terms) {
      case "Net 60":
        return 60;
      case "Net 30":
        return 30;
      case "Net 15":
        return 15;
      case "Immediate":
        return 0;
    }
  };

  handleDropdown = (event: any) => {
    this.setState({
      payment_terms: event.target.value
    })
  }
  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  // Upon submit click, we handle the changes to the corporate profile
  // Figure out how to handle contact changes
  handleClick = async () => {
    let NotificationHandler = this.props.ErrorNote;

    let corpEditBody = {
      id: this.state.id,
      fields: {
        corporate_name: this.state.corporation,
        client_manager: this.state.client_manager,
        method_of_payment: this.state.payment_type,
        first_last_policy: this.state.first_last,
        deposit_policy: this.state.charge_deposit,
        cleaning_fee: this.state.charge_cleaning,
        credit_fee: this.state.charge_credit_fee,
        communication_type: "online",
        active: true,
        invoicing_policy: this.state.invoice_method,
        notice_to_vacate: this.state.ntv,
        payment_terms: this.determinePaymentTerms(),
        notes: this.state.corporate_notes,
        address: this.state.address,
        finance_communication_policy: this.state.fin_com_pol,
        cleaning_communication_policy: this.state.clean_com_pol,
        admin_communication_policy: this.state.admin_com_pol,
        variable_communication_policy: this.state.var_com_pol,

        expiry: this.state.expiry
      },
    };

    const editCorpStatus = await this.editModel(
      Constants.API_PATH_CORP_EDIT,
      corpEditBody
    );
    
    if(editCorpStatus.status == "Success") {
      NotificationHandler('Success', 'Success', 'Corporate has been edited')
    } else {
      NotificationHandler(editCorpStatus.status, editCorpStatus.status, editCorpStatus.data)
    }
 
    let update = store.getState().corpContactReducer.corpViewUpdate;
    update();
  };

  editModel = async (link: string, editBody: any) => {
    return await fetchRequestJSON("PUT", link, editBody);
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Corporate";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry = [];

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    let Files = <></>;
    let AdditionalFiles = <></>;

    if (this.props.corporateData.file_names.length !== 0) {
      Files = this.props.corporateData.file_names.map(
        (name: any, index: any) => (
          <span className="px-2 fade-in text-gray-600 py-2 flex flex-row items-center hover:bg-gray-100 cursor-default border-b ">
            <AiOutlineFileText className="text-lg mr-1" />
            {name}{" "}
            <TiDelete
              onClick={() => this.props.handleFileRemove(index)}
              className="ml-auto text-2xl  hover:text-gray-400 cursor-pointer"
            />{" "}
          </span>
          // <div className="fade-in flex flex-row bg-gray-200 pl-4 pr-2 py-1 mr-3 rounded-full items-center">
          //   <span className="text-gray-600 text-sm mr-4">{unit}</span>{" "}
          //   <TiDelete  className="text-gray-500 text-lg hover:text-red-400 cursor-pointer" />
          // </div>
        )
      );
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Corporation Name:</span>
          <input
            id="corporation"
            onChange={this.handleInputChange}
            value={this.state.corporation}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Address</span>
          <input
            id="address"
            onChange={this.handleInputChange}
            value={this.state.address}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Client Manager</span>
          <select
            id="client_manager"
            value={this.state.client_manager}
            onChange={this.handleInputChange}
            className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option>Arlene Viana</option>
            <option>Cliff Norton</option>
            <option>Matt Regush</option>
            <option>Tracy Li</option>
            <option>Steven Argyris </option>
          </select>
        </label>

        <div className="  grid grid-cols-1 gap-1 px-3 py-2 ml-3 bg-gray-50 border rounded-sm">
          <div className={" block  py-2 border-b   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Deposit
              </span>

              <ToggleSwitch
                id="charge_deposit"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.charge_deposit}
              />
            </div>
          </div>

          <div className={" block  py-2 border-b   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Credit Fee
              </span>

              <ToggleSwitch
                id="charge_credit_fee"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.charge_credit_fee}
              />
            </div>
          </div>

          <div className={" block  py-2   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Cleaning Fee
              </span>

              <ToggleSwitch
                id="charge_cleaning"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.charge_cleaning}
              />
            </div>
          </div>
          <div className={" block  py-2   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Variable Communication Policy
              </span>

              <ToggleSwitch
                id="var_com_pol"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.var_com_pol}
              />
            </div>
          </div>
        </div>

        {/* <span className="text-gray-600 text-md font-bold  mt-8">
          Main Contacts{" "}
          <span className="text-gray-300 text-sm font-thin">
            {" "}
            (Click To Edit){" "}
          </span>
        </span>

        <div className="pl-3 my-4 grid-cols-1 gap-6 ">
          <ContactCard
            contactType="Admin"
            stateChange={this.handleInputChange}
            data={this.state}
          />
          <ContactCard
            contactType="Cleaning"
            stateChange={this.handleInputChange}
            data={this.state}
          />
          <ContactCard
            contactType="Finance"
            stateChange={this.handleInputChange}
            data={this.state}
          />
        </div> */}

        <div className="grid grid-cols-1 gap-4 w-full my-5">
          <label className="block  ">
            <span className="text-gray-400 text-xs">Payment Method:</span>
            <select
              id="payment_type"
              value={this.state.payment_type}
              onChange={this.handleInputChange}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>Credit</option>
              <option>Cheque</option>
              <option>Certified Cheque</option>
              <option>Wire </option>
              <option>Cash</option>
              <option>Direct Deposit</option>
              <option>PAD</option>
              <option>Terminal</option>
              <option>Rent Deductions</option>
              <option>Other</option>
              <option>TBD</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">
              {" "}
              Admin Communication Policy:
            </span>
            <select
              id="admin_com_pol"
              value={this.state.admin_com_pol}
              onChange={this.handleInputChange}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>Admin </option>
              <option>Tenant</option>
              <option>Both</option>
              <option selected>None</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">
              Finance Communication Policy:
            </span>
            <select
              id="fin_com_pol"
              value={this.state.fin_com_pol}
              onChange={this.handleInputChange}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>Finance </option>
              <option>Tenant</option>
              <option>Both</option>
              <option selected>None</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">
              Cleaning Communication Policy:
            </span>
            <select
              id="clean_com_pol"
              value={this.state.clean_com_pol}
              onChange={this.handleInputChange}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>Cleaning </option>
              <option>Tenant</option>
              <option>Both</option>
              <option selected>None</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">Invoicing Method:</span>
            <select
              id="invoice_method"
              value={this.state.invoice_method}
              onChange={this.handleInputChange}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>- </option>
              <option>Consolidated </option>
              <option>Individual</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">Payment Terms:</span>
            <select
              id="payment_terms"
              // value={this.state.payment_terms}
              onChange={this.handleDropdown}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option selected={this.state.isNet30 ? true : false }>Net 30</option>
              <option selected={!this.state.isNet30 ? true : false}>Immediate</option>
            </select>
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">Notice To Vacate:</span>
            <input
              id="ntv"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.ntv}
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder="--"
            />
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">Expiry Policy:</span>
            <input
              id="expiry"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.expiry}
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder="--"
            />
          </label>

          <label className="block  ">
            <span className="text-gray-400 text-xs">First/Last Policy:</span>
            <select
              id="first_last"
              value={this.state.first_last}
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option>None </option>
              <option>First/Last </option>
              <option>First Only</option>
              <option>Last Only</option>
              <option>None</option>
            </select>
          </label>
        </div>

        <label className="block  w-full   ">
          <span className="text-gray-500 text-base ">Files</span>
          <div
            // onDragOver={dragOver}
            // onDragEnter={dragEnter}
            // onDragLeave={dragLeave}
            // onDrop={fileDrop}
            className="form-input border-2 mt-3 cursor-pointer hover:bg-gray-100"
          >
            <span className="text-gray-600 flex flex-row w-full items-center align-center justify-center py-6 ">
              {" "}
              <AiOutlinePaperClip className="text-xl text-gray-600 mr-1" />{" "}
              <span className="text-blue-400 mr-1"> Add file </span>
            </span>
          </div>
          <input
            id="file"
            name="file"
            multiple
            onChange={this.props.handleFileChange}
            // value={inputISO(props.data.date_avail)}
            required
            type="file"
            className="hidden mt-1  w-full"
          />
        </label>
        {Files}

        <label className="block pl-3 mb-5 mt-8">
          <span className="text-gray-400 text-xs">Additional Notes</span>
          <textarea
            id="corporate_notes"
            onChange={this.handleInputChange}
            value={this.state.corporate_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.state.corporate_notes}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => {
              this.props.handleEditBarToggle();
              this.handleClick();
            }}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(EditCorporate);

export function inputISO(date: Date) {
  let year = date.getFullYear().toString();
  let month = date.getMonth();
  let dt = date.getDate();
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}

// export function ContactCard(props: any) {
//   var id = props.contactType.toLowerCase();
//   let name;
//   let email;
//   let phone;

//   if (props.contactType === "Admin") {
//     name = props.data.admin_contact;
//     email = props.data.admin_contact_email;
//     phone = props.data.admin_contact_phone;
//   }
//   if (props.contactType === "Finance") {
//     name = props.data.finance_contact;
//     email = props.data.finance_contact_email;
//     phone = props.data.finance_contact_phone;
//   }
//   if (props.contactType === "Cleaning") {
//     name = props.data.cleaning_contact;
//     email = props.data.cleaning_contact_email;
//     phone = props.data.cleaning_contact_phone;
//   }

//   return (
//     <div className="flex flex-col px-4 py-3 mb-4 h-auto border-2 rounded-sm border-solid border-gray-100 ">
//       <div className="flex flex-row w-full  items-center">
//         <span className="font-medium text-gray-700 text-md pb-1 ">
//           {props.contactType} -
//           <input
//             id={id + "_contact"}
//             type="text"
//             className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
//             value={name}
//             onChange={props.stateChange}
//             placeholder="Click To Edit Name"
//           />
//         </span>
//       </div>

//       <span className="font-regular text-gray-400 text-2xs pb-4">
//         Corporate - {props.data.corporation}
//       </span>
//       <input
//         id={id + "_contact_email"}
//         type="text"
//         className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
//         value={email}
//         onChange={props.stateChange}
//         placeholder="Click To Edit Email"
//       />
//       <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
//         <i className="text-gray-500 mr-1 ">
//           <FaPhoneAlt />
//         </i>
//         <input
//           id={id + "_contact_phone"}
//           type="text"
//           className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
//           value={phone}
//           onChange={props.stateChange}
//           placeholder="Click To Edit Phone"
//         />
//       </span>
//     </div>
//   );
// }
