export enum UserTypes {
  Admin = "Admin",
  Analytics = "Analytics",
  Business_Development = "Business_Development", // Corrected spelling
  CEO = "CEO",
  Cleaning = "Cleaning",
  CleanerAdmin = "CleanerAdmin",
  CleanersL1L3 = "CleanersL1L3",
  COO = "COO",
  Finance = "Finance",
  Finance_Controller = "Finance_Controller",
  Help_Offshore = "Help_Offshore",
  Office = "Office",
  Offshore = "Offshore",
  Ops = "Ops",
  Payments_Offshore = "Payments_Offshore",
  Primary = "Primary",
  QAM_L4 = "QAM_L4",
  RED = "RED",
  Sales = "Sales",
  Sales_Manager = "Sales_Manager",
  Sales_Offshore = "Sales_Offshore",
  Help_General_Offshore = "HelpGeneralOffshore",
  Help_Sales_Offshore = "HelpSalesOffshore",
  Payments_General_Offshore = "PaymentsGeneralOffshore",
  Service_Manager = "Service_Manager"
}

export const arUserTypes = [
  "Admin",
  "Analytics",
  "Business_Development",
  "CEO",
  "COO",
  "CleanerAdmin",
  "CleanersL1L3",
  "Cleaning",
  "Finance",
  "Finance_Controller",
  "Help_Offshore",
  "Office",
  "Offshore",
  "Payments_Offshore",
  "Primary",
  "QAM_L4",
  "RED",
  "Sales_Manager",
  "Sales_Offshore",
  "HelpGeneralOffshore",
  "HelpSalesOffshore",
  "PaymentsGeneralOffshore",
  "Service_Manager",
]

export type State = {
  userType: UserTypes[];
};

const initialState: State = {
  userType: [], // Initialize as an empty array
};


type Action = { type: "changeUserType"; newUserType: UserTypes};

export const userReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "changeUserType":
      if(action?.newUserType && !state.userType.includes(action?.newUserType)) {
        state.userType.push(action?.newUserType);
      }
      return state;
    default:
      return state;
  }
};

export default userReducer;
