import { Button } from "@mui/material";
import React, { useState } from "react";
import { MdEdit, MdOpenInNew } from "react-icons/md";
import { useNotification } from "../Notifications/NotificationProvider";
import { ExtensionCreateModal } from "./ExtensionCreateModal";
import { ShortenCreateModal } from "./ShortenCreateModal";
import { CgExtensionAdd, CgSandClock } from "react-icons/cg";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsFillFlagFill } from "react-icons/bs";
import { RiExchangeDollarLine } from "react-icons/ri";
import { ImCancelCircle } from "react-icons/im";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";

interface Props {
    handleModalToggle?: any;
    onoff: boolean;
    toggleEarlyCheckInModal?: any;
    toggleCheckInKeys?: any; //Don't need this, this will navigate via a history.push
    toggleShortenModal?:any;  //Inside checkoutprofile
    toggleExtensionModal?:any; //Inside checkoutprofile
    toggleCheckOutModal?:any;
    toggleFlagModal?: any;
    toggleTransferModal?:any;
    toggleCancelModal?: any;
    toggleRateModal?:any;
    toggleEditModal?:any;
    toggleExpiryModal?:any;
    checkIn:string;
    checkOut: string;
    id: number;
    suiteName: string;
    rentalsUnitedID:number;
}

function EditPortalModal(props:Props) {
    const [onOff, setOnOff] = useState(false);
    const [earlyCheckIn, setEarlyCheckIn] = useState('');
    const [lateCheckIn, setLateCheckIn] = useState('');
    const [activeCheckInModification, setActiveCheckInModification] = useState(false);
    const [activeCheckOutModification, setActiveCheckOutModification] = useState(false);
    const [activeUnitTransfer, setActiveUnitTransfer] = useState(false);
    const [activeOtherModification, setActiveOtherModification] = useState(false);
    const [cancelStatusModification, setCancelStatusModification] = useState(false);

    const [openExtension, setOpenExtension] = useState<boolean>(false);
    const [openShorten, setOpenShorten] = useState<boolean>(false);

    //Front End UI State Variables:
    const [selection, setSelection] = useState('');

    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }

    const handleModal = (event: any) => {
      if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
        props.handleModalToggle(props.onoff);
        if (onOff === false) {
          setOnOff(true)
        } else {
          setOnOff(false)
        }
      }
    };

    const closeExtensionModal = () => {
      setOpenExtension(false)
    }

    const closeShortenModal = () => {
      setOpenShorten(false)
    }

    const earlyCheckInModal = () => {
      if (typeof (earlyCheckIn) !== "string" || earlyCheckIn == "") {
        NotificationHandler("Error", "Error", "No date entered to change check in.")
      }
      else if (earlyCheckIn == props.checkIn) {
        NotificationHandler("Warning", "Warning", "New check in date is the same as the current check in date.")
      }
      else if (new Date(earlyCheckIn) > new Date(props.checkIn)) {
        NotificationHandler("Warning", "Warning", "Early check in date is later than the current check in date.")
      }
      else {
        props.toggleEarlyCheckInModal(earlyCheckIn, true);
      }
    }

    const lateCheckInModal = () => {
      console.log(lateCheckIn)
      if (typeof (lateCheckIn) !== "string" || lateCheckIn == "") {
        NotificationHandler("Error", "Error", "No date entered to change check in.")
      }
      else if (lateCheckIn == props.checkIn) {
        NotificationHandler("Warning", "Warning", "New check in date is the same as the current check in date.")
      }
      else if (new Date(lateCheckIn) < new Date(props.checkIn)) {
        NotificationHandler("Warning", "Warning", "Late check in date is later than the current check in date.")
      }
      else {
        props.toggleEarlyCheckInModal(lateCheckIn, false);
      }
    }

    const handleButtonClick = async (selectedVariable:any, selectedVariableSetter:any, unitTransfer:boolean=false) => {
      setActiveCheckInModification(false);
      setActiveCheckOutModification(false);
      setActiveUnitTransfer(false);
      setActiveOtherModification(false);
      setCancelStatusModification(false);
      
      //Assign State Variables:
      selectedVariableSetter(!selectedVariable);

      //Restart selection
      setSelection('');
      if(unitTransfer) {
        props.toggleTransferModal();
        // props.handleModalToggle(props.onoff);
      }
    };

    const handleSelect = (option: React.SetStateAction<string>) => {
      setSelection(option);
    };

    const openCheckOutModal = (modification:string) => {
      if(props.rentalsUnitedID) {
        NotificationHandler("Error", "Error", "Cannot edit check-in or check-out for a Rentals United Reservation from the Admin System. Only allowed from Sales Channel and Rentals United.")
        return
      }

      if(modification == 'extend') {
        setOpenExtension(true)
      } else {
        setOpenShorten(true)
      }
      // props.handleModalToggle(props.onoff);
    }

    const captureID = async() => {
      const url = `${window.location.origin}/User/Check%20Ins/BookingId?id=${props.id}`
      window.open(url, "_blank")
    }

    const keyCafeUpdate = async() => {
      const booking_id = props.id
      const suiteName = props.suiteName

      console.log(props.suiteName)
  
      NotificationHandler(
        "Processing",
        `Updating KeyCode`,
        "Please wait while we update this booking's keycode to KeyCafe",
      );
  
      const forceKeyCafe = await fetchRequestJSON("PUT",
        Constants.API_PATH_FORCE_KEYCAFE, {
        bookingId: booking_id,
        suite_name: suiteName
      })
  
      if(forceKeyCafe?.status == "Success") {
       NotificationHandler("Success",
        `Updated KeyCode`,
         `${forceKeyCafe.data}`,)
      } else {
        NotificationHandler("Error",
        `Unsuccesful KeyCode Update`,
         `${forceKeyCafe?.status}`,)
      }
    }

    const sendBookingConfirmation = async() => {
      const booking_id = props.id;
  
      const sendBookingConfirmation = await fetchGetJSON(`${Constants.API_PATH_SEND_BOOKING_CONFIRMATIONFORM}${booking_id}`)
    
      if(sendBookingConfirmation?.status == "Success") {
        console.log(sendBookingConfirmation.data.length)
  
       NotificationHandler("Success",
        `Success`,
        `Booking Confirmation Email sent to ${Array.isArray(sendBookingConfirmation.data) ? `${sendBookingConfirmation?.data[0]} and ${sendBookingConfirmation?.data[1]}` : sendBookingConfirmation.data}`
        )
      } else {
        NotificationHandler("Error",
        `Unsuccesful in Sending Booking Confirmation`,
         `${sendBookingConfirmation?.status}`,)
      }
    }
    
    return (
      <>
        <ExtensionCreateModal id={props.id} checkOut={props.checkOut} open={openExtension} closeModal={closeExtensionModal}/>
        <ShortenCreateModal  id={props.id} checkOut={props.checkOut} open={openShorten} closeModal={closeShortenModal}/> 
        <div
            id="outsideDiv"
            onClick={(event) => handleModal(event)}
            className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        >
            <div className=" appear bg-white shadow-lg rounded-lg h-auto w-auto py-8 px-10 mx-auto flex flex-col">
                  <h1 className="h1">What Booking Modification do you want to handle?</h1>
                  <h2 className="h2">Note: "Other" includes Flagging the Booking, Rate Change, and General Edits</h2>
                  <div className="flex flex-row w-full mt-2">
                      <button 
                        className={`flex-auto shadow-md transition text-md duration-100 w-44 ${activeCheckInModification ? 'bg-green-700' :'bg-green-500 hover:bg-green-400'} text-white font-semibold py-2 rounded pr-1 mx-5 px-3`}
                        onClick={() => handleButtonClick(activeCheckInModification, setActiveCheckInModification)}
                      >
                        Check In Modification
                      </button>
                      <button 
                        className={`flex-auto shadow-md transition text-md duration-100 w-44 ${activeCheckOutModification ? 'bg-green-700' :'bg-green-500 hover:bg-green-400'} text-white font-semibold py-2 rounded pr-1 mx-5`}
                        onClick={() => handleButtonClick(activeCheckOutModification, setActiveCheckOutModification)}
                      >
                        Check Out Modification
                      </button>
                      <button 
                        className={`flex-auto shadow-md transition text-md duration-100 w-44 ${activeUnitTransfer ? 'bg-green-700' :'bg-green-500 hover:bg-green-400'} text-white font-semibold py-2 rounded pr-1 mx-5`}
                        onClick={() => handleButtonClick(activeUnitTransfer, setActiveUnitTransfer, true)}
                      >
                        Unit Transfer Modification
                      </button>
                      <button
                        className={`flex-auto shadow-md transition text-md duration-100 w-44 ${activeOtherModification ? 'bg-green-700' :'bg-green-500 hover:bg-green-400'} text-white font-semibold py-2 rounded pr-1 mx-5`}
                        onClick={() => handleButtonClick(activeOtherModification, setActiveOtherModification)}
                      >
                      "Other" Modification
                      </button>
                      <button
                        className={`flex-auto shadow-md transition text-md duration-100 w-44 ${cancelStatusModification ? 'bg-red-700' :'bg-red-500 hover:bg-red-400'} text-white font-semibold py-2 rounded pr-1 mx-5`}
                        onClick={() => handleButtonClick(cancelStatusModification, setCancelStatusModification)}
                      >
                        Cancel or Release Modification
                      </button>
                  </div>


                  {
                    activeCheckInModification &&
                    <div className="transition duration-200 scale-in-ver-top">
                      <h1 className="h3 mt-5">Are you checking in keys/fobs or doing an early check in?</h1>
                      <div className="flex flex-col items-center justify-center p-4">
                          <div className="space-x-4">
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'early' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('early')}
                              >
                                  Early Check-In
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'late' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('late')}
                              >
                                  Late Check-In
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'keys' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('keys')}
                              >
                                  Check-In Keys
                              </button>
                              <button
                                className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'keycode' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                onClick={() => handleSelect('keycode')}
                              >
                                Key Code Update
                              </button>
                              <button
                                className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'sendBookingConfirmationReminder' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                onClick={() => handleSelect('sendBookingConfirmationReminder')}
                              >
                                Send Booking Confirmation
                              </button>
                          </div>
                          {selection && <p className="mt-4 text-lg">You selected: <strong>{selection === 'early' ? 'Early Check-In' : selection === 'keycode' ? 'Key Code Update' : selection === 'keys' ? 'Check-In Keys' : selection === 'late' ? 'Late Check-In' : 'Send Booking Confirmation Reminder Email'}</strong></p>}
                      </div>
                      {
                        selection == 'keys' &&
                        <>
                          <div className="transition duration-200 scale-in-ver-top">
                            <div className="mt-5 flex flex-row justify-left justify-center">
                              <button
                                className="hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3 w-80"
                                onClick={() => captureID()}
                              >
                                Check In Keys and Fobs
                              </button>  
                            </div>
                          </div>
                        </>
                      }
                      {
                        selection == 'early' &&
                        <>
                          <div className="transition duration-200 scale-in-ver-top">
                            <h3 className="h3">
                            Early Check In:
                            </h3>
                            <section className="mt-3 py-5 border-2 border-gray-100 border-solid w-full">
                              <span className="flex flex-row ml-3">
                                <input
                                  id="check_in"
                                  onChange={(event) => setEarlyCheckIn(event.target.value)}
                                  type="date"
                                  className="mt-0 ml-3 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                />
                                <button
                                  className="ml-6 text-green-500 mr-10"
                                  onClick={() => {
                                    earlyCheckInModal()
                                  }}
                                >
                                  Confirm
                                </button>
                              </span>
                            </section>
                          </div>
                        </>
                      }
                      {
                        selection == 'late' &&
                        <>
                          <div className="transition duration-200 scale-in-ver-top">
                            <h3 className="h3">
                            Late Check In:
                            </h3>
                            <section className="mt-3 py-5 border-2 border-gray-100 border-solid w-full">
                              <span className="flex flex-row ml-3">
                                <input
                                  id="check_in"
                                  onChange={(event) => setLateCheckIn(event.target.value)}
                                  type="date"
                                  className="mt-0 ml-3 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                />
                                <button
                                  className="ml-6 text-green-500 mr-10"
                                  onClick={() => {
                                    lateCheckInModal()
                                  }}
                                >
                                  Confirm
                                </button>
                              </span>
                            </section>
                          </div>
                        </>
                      }
                      {
                        selection == 'keycode' &&
                        <>
                          <div className="transition duration-200 scale-in-ver-top">
                            <div className="mt-5 flex flex-row justify-left justify-center">
                              <button
                                className="hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3 w-80"
                                onClick={() => keyCafeUpdate()}
                              >
                                KeyCode Update
                              </button>  
                            </div>
                          </div>
                        </>
                      }
                      {
                        selection == 'sendBookingConfirmationReminder' &&
                        <>
                          <div className="transition duration-200 scale-in-ver-top">
                            <div className="mt-5 flex flex-row justify-left justify-center">
                              <button
                                className="hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3 w-80"
                                onClick={() => sendBookingConfirmation()}
                              >
                                Sending Booking Confirmation Reminder
                              </button>  
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  }

                  {
                    activeCheckOutModification &&
                    <div className="transition duration-200 scale-in-ver-top">
                      <h1 className="h3 mt-5">Are you shortening the booking, creating an extension, or checking out keys?</h1>
                      <div className="flex flex-col items-center justify-center p-4">
                          <div className="space-x-4">
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'extension' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('extension')}
                              >
                                  Create an Extension
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'shorten' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('shorten')}
                              >
                                  Shortening the Booking
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'checkOutKeys' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('checkOutKeys')}
                              >
                                  Checking Out Keys
                              </button>
                          </div>
                          {selection && <p className="mt-4 text-lg">You selected: <strong>{selection === 'extension' ? 'Extension' : selection === 'shorten' ? 'Shorten Booking' : 'Checking Out Keys'}</strong></p>}
                      </div>
                      {
                        selection == 'extension' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                          <button
                            onClick={() => openCheckOutModal('extend')}
                            className="w-80 mt-3 mb-3 lg:mt-0 hover:bg-green-400 hover:text-white hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row justify-center text-m"
                          >
                            <CgExtensionAdd className="text-2xl " />
                            <span className="ml-2">Request Extension</span>
                          </button>
                        </div>
                      }
                      {
                        selection == 'shorten' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                          <button
                            onClick={() => openCheckOutModal('shorten')}
                            className="w-80 mt-3 mb-3 lg:mt-0 hover:bg-green-400 hover:text-white hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row justify-center text-m"
                          >
                            <AiOutlineClockCircle className="text-2xl " />
                            <span className="ml-2">Shorten Stay</span>
                          </button>
                        </div>
                      }
                      {
                        selection == 'checkOutKeys' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                          <button
                            onClick={() => props.toggleCheckOutModal()}
                            className="transition max-w duration-200 hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded-sm text-white font-bold shadow-sm"
                          >
                            Check Out Keys and Fobs
                          </button>
                        </div>
                      }
                    </div>
                  }

                  {
                    activeOtherModification &&
                    <div className="transition duration-200 scale-in-ver-top">
                      <h1 className="h3 mt-5">Please select the following edits that apply: </h1>
                      <div className="flex flex-col items-center justify-center p-4">
                          <div className="space-x-4">
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'generalEdits' ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-400'}`}
                                  onClick={() => handleSelect('generalEdits')}
                              >
                                  General Edits
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'rateChange' ? 'bg-green-500' : 'bg-gray-300 hover:bg-green-400'}`}
                                  onClick={() => handleSelect('rateChange')}
                              >
                                  Rate Change
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'flag' ? 'bg-red-500' : 'bg-gray-300 hover:bg-red-400'}`}
                                  onClick={() => handleSelect('flag')}
                              >
                                  Flagging Booking
                              </button>
                          </div>
                          {selection && <p className="mt-4 text-lg">You selected: <strong>{selection === 'generalEdits' ? 'General Edits' : selection === 'flag' ? 'Flag the Booking' : selection ==="rateChange" ? 'Rate Change' : selection === "cancel" ? 'Cancel Booking' : 'Expire Booking'}</strong></p>}
                      </div>
                      {
                        selection === 'generalEdits' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                          <button
                            onClick={() => props.toggleEditModal()}
                            className="w-80 mt-3 mb-3 lg:mt-0 hover:bg-green-400 hover:text-white hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row justify-center text-m"
                          >
                            <MdEdit className="text-2xl"/>
                            <span className="ml-2">General Edits</span>
                          </button>
                        </div>
                      }
                      {
                        selection === 'rateChange' && 
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleRateModal()}
                          className="w-80 mt-3 mb-3 lg:mt-0 hover:bg-green-400 hover:text-white hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-green-600 border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <RiExchangeDollarLine className="text-2xl" />
                            <span className="ml-2">Rate Change</span>
                          </button>
                        </div>
                      }
                      {
                        selection === 'flag' && 
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleFlagModal()}
                          className="w-80 mt-3 mb-3 lg:mt-0 hover:bg-red-400 hover:text-white hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <BsFillFlagFill className='text-2xl' />
                            <span className="ml-2">Flag Booking</span>
                          </button>
                        </div>
                      }
                      {
                        selection === 'cancel' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleCancelModal()}
                          className="bg-red-500 w-80 mt-3 mb-3 lg:mt-0 hover:bg-red-400 hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-white border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <ImCancelCircle className="text-2xl" />
                            <span className="ml-2">Cancel Booking</span>
                          </button>
                        </div>
                      }
                      {
                        selection === 'expire' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleExpiryModal()}
                          className="bg-orange-500 w-80 mt-3 mb-3 lg:mt-0 hover:bg-orange-400 hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-white border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <CgSandClock className="text-2xl" />
                            <span className="ml-2">Expire Booking</span>
                          </button>
                        </div>
                      }
                    </div>
                  }
                  {
                    cancelStatusModification &&
                    <div className="transition duration-200 scale-in-ver-top">
                      <h1 className="h3 mt-5">Is this a tenant requested cancellation or a release?</h1>
                      <p><strong>Reminder: </strong>Releasing a booking is a decision made by internal staff and <strong>will require a reason.</strong> Cancellation is upon request by Tenant.</p>
                      <div className="flex flex-col items-center justify-center p-4">
                      <div className="space-x-4">
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'cancel' ? 'bg-red-500' : 'bg-gray-300 hover:bg-red-400'}`}
                                  onClick={() => handleSelect('cancel')}
                              >
                                  Tenant Requested Cancellation
                              </button>
                              <button
                                  className={`px-4 py-2 text-white font-semibold rounded-lg ${selection === 'release' ? 'bg-blue-600' : 'bg-gray-300 hover:bg-blue-500'}`}
                                  onClick={() => handleSelect('release')}
                              >
                                  Release Booking
                              </button>
                          </div>
                          {selection && <p className="mt-4 text-lg">You selected: <strong>{selection === 'cancel' ? 'Cancellation' : 'Release'}</strong></p>}
                      </div>
                      {
                        selection === 'cancel' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleCancelModal()}
                          className="bg-red-500 w-80 mt-3 mb-3 lg:mt-0 hover:bg-red-400 hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-white border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <ImCancelCircle className="text-2xl" />
                            <span className="ml-2">Cancel Booking</span>
                          </button>
                        </div>
                      }
                      {
                        selection === 'release' &&
                        <div className="flex flex-row justify-center transition duration-200 scale-in-ver-top">
                        <button
                          onClick={() => props.toggleExpiryModal()}
                          className="bg-orange-500 w-80 mt-3 mb-3 lg:mt-0 hover:bg-orange-400 hover:font-semibold rounded-sm py-2 px-4 whitespace-no-wrap text-white border mr-0 pt-2 flex flex-row justify-center text-m"
                        >
                            <CgSandClock className="text-2xl" />
                            <span className="ml-2">Release Booking</span>
                          </button>
                        </div>
                      }
                    </div>
                  }
            </div>
        </div>
      </>
    )
}

export default EditPortalModal;
